import {configureStore} from '@reduxjs/toolkit';

import accessReport, * as fromAccessReport from './reducers/access-report';
import assessment, * as fromAssessment from './reducers/assessment';
import assessments, * as fromAssessments from './reducers/assessments';
import assessmentQuestion, * as fromAssessmentQuestion from './reducers/assessment-question';
import assessmentCorrect, * as fromAssessmentCorrect from './reducers/assessment-correct';
import assessmentCorrectSameAllStudents, * as fromAssessmentCorrectSameAllStudents from './reducers/assessment-correct-same-all-students';
import assessmentCorrectSummary, * as fromAssessmentCorrectSummary from './reducers/assessment-correct-summary';
import assessmentPendingEssay, * as fromAssessmentPendingEssay from './reducers/assessment-pending-essay';
import assessmentQuestions, * as fromAssessmentQuestions from './reducers/assessment-questions';
import bookmarks from './reducers/bookmarks';
import chapterId_current, * as fromChapterIdCurrent from './reducers/chapterId-current';
import chapterListLevels, * as fromChapterListLevels from './reducers/chapter-list-levels';
import chaptersList, * as fromChapters from './reducers/chapters';
import colorsEditor, * as fromColorsEditor from './reducers/colors-editor';
import contactFilter, * as fromContactFilter from './reducers/contact-filter';
import contactMembers, * as fromContactMembers from './reducers/contact-members';
import contacts, * as fromContacts from './reducers/contacts';
import contributorReport, * as fromContributorReport from './reducers/contributor-report';
import declineIdleList, * as fromDeclineIdleList from './reducers/decline-idle-list';
import draftComparison, * as fromDraftComparison from './reducers/draft-comparison';
import draftReport, * as fromDraftReport from './reducers/draft-report';
import draftReview, * as fromDraftReview from './reducers/draft-review';
import draftSettings, * as fromDraftSettings from './reducers/draft-settings';
import editDetails, * as fromEditDetails from './reducers/edit-details.js';
import editFilter from './reducers/edit-filter';
import editMicroReplace, * as fromEditMicroReplace from './reducers/edit-micro-replace';
import editorInviteGUIDResponse, * as fromEditorInviteResponse from './reducers/editor-invite-response';
import editorInviteName, * as fromEditorInviteName from './reducers/editor-invite-name';
import editorInvitePending, * as fromEditorInvitePending from './reducers/editor-invite-pending';
import editorInviteWorkAssign, * as fromEditorInviteWorkAssign from './reducers/editor-invite-work';
import editReview, * as fromEditReview from './reducers/EditReview/edit-review.js';  //I don't think that this is being used.
import editSegmentHistory, * as fromEditSegmentHistory from './reducers/edit-segment-history.js'
import editSegments, * as fromEditSegments from './reducers/edit-segments.js'
import editSeverityList, * as fromEditSeverityList from './reducers/edit-severity-list';
import fetchingRecord, * as fromFetchingRecord from './reducers/fetching-record';
import friendInvitation, * as fromFriendInvitation from './reducers/friend-invitation.js';
import genreList, * as fromGenreList from './reducers/genre-list';
import groupByName, * as fromGroupByName from './reducers/group-by-name.js';
import groupContactsWorks, * as fromGroupContactsWorks from './reducers/group-contacts-works';
import groupEditReport, * as fromGroupEditReport from './reducers/group-edit-report';
import groupFileTree, * as fromGroupFileTree from './reducers/group-file-tree-explorer';
import groupId_current from './reducers/groupId-current';
import groups, * as fromGroups from './reducers/groups';
import groupMembers, * as fromGroupMembers from './reducers/group-members';
import groupTypes, * as fromGroupTypes from './reducers/group-types';
import groupWorkAssignAccess, * as fromGroupWorkAssignAccess from './reducers/group-work-assign-access';
import htmlCharacterEntities, * as fromHtmlCharacterEntities from './reducers/html-character-entities.js';
import isEditorInviteActive, * as fromIsEditorInviteActive from './reducers/editor-invite-is-active';
import landingSteps, * as fromLandingSteps from './reducers/landing-steps';
import languageId_current, * as fromLanguageIdCurrent from './reducers/languageId-current';
import languages, * as fromLanguages from './reducers/language-list';
import leftSidePanelOpen, * as fromLeftSidePanel from './reducers/left-side-panel';
import listLevelGeneral, * as fromListLevelGeneral from './reducers/list-levels';
import loggedIn from './reducers/logged-in';
import me, * as fromMe from './reducers/login';
import myProfile, * as fromMyProfile from './reducers/my-profile';
import openCommunity, * as fromOpenCommunity from './reducers/open-community';
import openCommunityFilter, * as fromOpenCommunityFilter from './reducers/open-community-filter';
import pageMeta, * as fromPageMeta from './reducers/page-meta';
import peerGroup, * as fromPeerGroup from './reducers/peer-group';
import peopleByGroupIds, * as fromPeopleByGroupIds from './reducers/people-by-group-ids';
import personConfig, * as fromPersonConfig from './reducers/person-config';
import personId_current, * as fromPersonIdCurrent from './reducers/personId-current';
import questionTypes, * as fromQuestionTypes from './reducers/question-types';
import reportFilter, * as fromReportFilter from './reducers/report-filter';
import reportFilterOptions from './reducers/report-filter-options';
import system, * as fromSystem from './reducers/system';
import textImageGroups, * as fromTextImageGroups from './reducers/text-image-groups.js';
import textImageUploads, * as fromTextImageUploads from './reducers/text-image-upload.js';
import textProcessingProgress, * as fromTextProcessingProgress from './reducers/text-processing-progress';
import textStyleFonts, * as fromTextStyleFonts from './reducers/text-style-fonts.js';
import textStyleGroups, * as fromTextStyleGroups from './reducers/text-style-groups.js';
import translatedSentence, * as fromTranslatedSentence from './reducers/translated-sentence';
import translations, * as fromTranslations from './reducers/translations';
import usernameToVerify, * as fromUsernameToVerify from './reducers/username-verify';
import voiceRecording, * as fromVoiceRecording from './reducers/voice-recording';
import workAddSettings, * as fromWorkAddSettings from './reducers/work-add-setting';
import workByGroupIds, * as fromWorkByGroupIds from './reducers/work-by-group-ids';
import workEditorAccess, * as fromWorkEditorAccess from './reducers/work-editor-access';
import workEditReport, * as fromWorkEditReport from './reducers/work-edit-report';
import workEditReview, * as fromWorkEditReview from './reducers/work-edit-review';
import workFileTree, * as fromWorkFileTree from './reducers/work-file-tree-explorer';
import workFilter, * as fromWorkFilter from './reducers/work-filter';
import workId_current, * as fromWorkIdCurrent from './reducers/workId-current';
import works, * as fromWorks from './reducers/works';
import workSegments, * as fromWorkSegments from "./reducers/work-segments";
import workSegmentUploadProgress, * as fromWorkSegmentUploadProgress from './reducers/work-segment-upload-progress';
import workStatusList, * as fromWorkStatusList from './reducers/work-status-list';
import workSummarySingle, * as fromWorkSummarySingle from "./reducers/work-summary";
import workTypes, * as fromWorkTypes from './reducers/work-types';


export const store = configureStore({
  reducer: {
    accessReport,
    assessment,
    assessments,
    assessmentQuestion,
    assessmentCorrect,
    assessmentCorrectSameAllStudents,
    assessmentCorrectSummary,
    assessmentPendingEssay,
    assessmentQuestions,
    bookmarks,
    chapterId_current,
    chapterListLevels,
    chaptersList,
    colorsEditor,
    contactFilter,
    contactMembers,
    contacts,
    contributorReport,
    declineIdleList,
    draftComparison,
    draftReport,
    draftReview,
    draftSettings,
    editDetails,
    editFilter,
    editMicroReplace,
    editorInviteGUIDResponse,
    editorInviteName,
    editorInvitePending,
    editorInviteWorkAssign,
    editReview,
    editSegmentHistory,
    editSegments,
    editSeverityList,
    fetchingRecord,
    friendInvitation,
    genreList,
    groupByName,
    groupContactsWorks,
    groupEditReport,
    groupFileTree,
    groupId_current,
    groups,
    groupMembers,
    groupTypes,
    groupWorkAssignAccess,
    htmlCharacterEntities,
    isEditorInviteActive,
    landingSteps,
    languageId_current,
    languages,
    leftSidePanelOpen,
    listLevelGeneral,
    loggedIn,
    me,
    myProfile,
    openCommunity,
    openCommunityFilter,
    pageMeta,
    peerGroup,
    peopleByGroupIds,
    personConfig,
    personId_current,
    questionTypes,
    reportFilter,
    reportFilterOptions,
    system,
    textImageGroups,
    textImageUploads,
    textProcessingProgress,
    textStyleFonts,
    textStyleGroups,
    translatedSentence,
    translations,
    usernameToVerify,
    voiceRecording,
    workAddSettings,
    workByGroupIds,
    workEditorAccess,
    workEditReport,
    workEditReview,
    workFileTree,
    workFilter,
    workId_current,
    works,
    workSegments,
    workSegmentUploadProgress,
    workStatusList,
    workSummarySingle,
    workTypes,
  },
});

export const selectHTTPResponseCode = (state) => (
  fromSystem.selectHTTPResponseCode(state.system)
);

export const selectAllApplicationErrors = (state) => (
  fromSystem.selectAllApplicationErrors(state.system)
);

export const selectApplicationError = (state, id) => (
  fromSystem.selectApplicationError(state.system, id)
);

export const selectLanguages = (state) => (
  fromLanguages.selectLanguages(state.languages)
);

export const selectMe = (state) => (
  fromMe.selectMe(state.me)
);

export const selectEditorInviteGUIDResponse = (state) => (
  fromEditorInviteResponse.selectEditorInviteGUIDResponse(state.editorInviteGUIDResponse)
);

export const selectGenreList = (state) => (
  fromGenreList.selectGenreList(state.genreList)
);

export const selectGroupTypes = (state) => (
  fromGroupTypes.selectGroupTypes(state.groupTypes)
);

export const selectGroups = (state) => (
  fromGroups.selectGroups(state.groups)
);

export const selectPeerGroup = (state) => (
  fromPeerGroup.selectPeerGroup(state.peerGroup)
);

export const selectDeclineIdleList = (state) => (
  fromDeclineIdleList.selectDeclineIdleList(state.declineIdleList)
);

export const selectColorsEditor = (state) => (
  fromColorsEditor.selectColorsEditor(state.colorsEditor)
);

export const assignColorsEditor = (state) => (
  fromColorsEditor.assignColorsEditor(state.colorsEditor, state.editReview.editDetails)
);

export const selectWorkStatusList = (state) => (
  fromWorkStatusList.selectWorkStatusList(state.workStatusList)
);

export const selectEditSeverityList = (state) => (
  fromEditSeverityList.selectEditSeverityList(state.editSeverityList)
);

export const selectEditorInviteName = (state) => (
  fromEditorInviteName.selectEditorInviteName(state.editorInviteName)
);

export const selectEditorInvitePending = (state) => (
  fromEditorInvitePending.selectEditorInvitePending(state.editorInvitePending)
);

export const selectEditorInviteWorkAssign = (state) => (
  fromEditorInviteWorkAssign.selectEditorInviteWorkAssign(state.editorInviteWorkAssign)
);

export const selectWorks = (state) => (
  fromWorks.selectWorks(state.works)
);

export const selectWorkEditReview = (state) => (
  fromWorkEditReview.selectWorkEditReview(state.workEditReview)
);

export const selectWorkEditReport = (state) => (
  fromWorkEditReport.selectWorkEditReport(state.workEditReport)
);

export const selectChaptersList = (state) => (
  fromChapters.selectChaptersList(state.chaptersList)
);

export const selectChapterListLevels = (state) => (
  fromChapterListLevels.selectChapterListLevels(state.chapterListLevels)
);

export const selectDraftSettings = (state) => (
  fromDraftSettings.selectDraftSettings(state.draftSettings)
);

export const selectOpenCommunity = (state) => (
  fromOpenCommunity.selectOpenCommunity(state.openCommunity)
);

export const selectContributorReport = (state) => (
  fromContributorReport.selectContributorReport(state.contributorReport)
);

export const selectAccessReport = (state) => (
  fromAccessReport.selectAccessReport(state.accessReport)
);

export const selectGroupWorkAssignAccess = (state) => (
  fromGroupWorkAssignAccess.selectGroupWorkAssignAccess(state.groupWorkAssignAccess)
);

export const selectGroupEditReport = (state) => (
  fromGroupEditReport.selectGroupEditReport(state.groupEditReport)
);

export const selectEditMicroReplace = (state) => (
  fromEditMicroReplace.selectEditMicroReplace(state.editMicroReplace)
);

export const selectWorkSummary = (state, workId) => {
  return fromWorks.selectWorkSummary(state.works, workId, workId_current, me.personId);
};

export const selectWorkSummaryCurrent = (state) => {
  return fromWorks.selectWorkSummary(state.works, state.workId_current, state.workId_current, state.me.personId);
};

export const selectWorkFilter = (state) => (
  fromWorkFilter.selectWorkFilter(state.workFilter)
);

export const selectContactFilter = (state) => (
  fromContactFilter.selectContactFilter(state.contactFilter)
);

export const selectOpenCommunityFilter = (state) => (
  fromOpenCommunityFilter.selectOpenCommunityFilter(state.openCommunityFilter)
);

export const selectReportFilter = (state) => (
  fromReportFilter.selectReportFilter(state.reportFilter)
);

export const selectLeftSidePanelOpen = (state) => (
  fromLeftSidePanel.selectLeftSidePanelOpen(state.leftSidePanelOpen)
);

export const selectWorkIdCurrent = (state) => (
  fromWorkIdCurrent.selectWorkIdCurrent(state.workId_current)
);

export const selectGroupIdCurrent = (state) => state.groupId_current;

export const selectChapterIdCurrent = (state) =>
  fromChapterIdCurrent.selectChapterIdCurrent(state.chapterId_current)

export const selectLanguageIdCurrent = (state) => (
  fromLanguageIdCurrent.selectLanguageIdCurrent(state.languageId_current)
);

export const selectPersonIdCurrent = (state) => (
  fromPersonIdCurrent.selectPersonIdCurrent(state.personId_current || 0)
);

export const selectPersonConfig = (state) => (
  fromPersonConfig.selectPersonConfig(state.personConfig || 0)
);

export const selectTranslations = (state) => (
  fromTranslations.selectTranslations(state.translations)
);

export const selectPageMeta = (state) => (
  fromPageMeta.selectPageMeta(state.pageMeta)
);

export const selectPageTitle = (state) => (
  fromPageMeta.selectPageTitle(state.pageMeta)
);

export const selectMetaTags = (state) => (
  fromPageMeta.selectMetaTags(state.pageMeta)
);

export const selectContacts = (state) => (
  fromContacts.selectContacts(state.contacts)
);

export const selectTextProcessingProgress = (state) => (
  fromTextProcessingProgress.selectTextProcessingProgress(state.textProcessingProgress)
);

export const selectMyProfile = (state) => (
  fromMyProfile.selectMyProfile(state.myProfile)
);

export const selectEditReview = (state, personId, authorPersonId, workLanguageId) =>
  fromEditReview.selectEditReview(state.editReview, personId, authorPersonId, workLanguageId, state.languageId_current);

export const selectEditDetails = (state) =>
  fromEditDetails.selectEditDetails(state.editDetails);

export const selectChapterText = (state) => (
  fromEditReview.selectChapterTextOriginal(state.editReview)
);

export const selectLandingSteps = (state) => (
  fromLandingSteps.selectLandingSteps(state.landingSteps)
);

export const selectWorkSegments = (state) => (
  fromWorkSegments.selectWorkSegments(state.workSegments)
);

export const selectFetchingRecord = (state) => (
  fromFetchingRecord.selectFetchingRecord(state.fetchingRecord)
);

export const selectEditorInviteIsActive = (state) => (
  fromIsEditorInviteActive.selectEditorInviteIsActive(state.isEditorInviteActive)
);

export const selectEditSegments = (state) => (
  fromEditSegments.selectEditSegments(state.editSegments)
);

export const selectWorkSummarySingle = (state) => (
  fromWorkSummarySingle.selectWorkSummarySingle(state.workSummarySingle)
);

export const selectWorkFileTree = (state) => (
  fromWorkFileTree.selectWorkFileTree(state.workFileTree)
);

export const selectGroupFileTree = (state) => (
  fromGroupFileTree.selectGroupFileTree(state.groupFileTree)
);

export const selectWorkEditorAccess = (state) => (
  fromWorkEditorAccess.selectWorkEditorAccess(state.workEditorAccess)
);

export const selectUsernameToVerify = (state) => (
  fromUsernameToVerify.selectUsernameToVerify(state.usernameToVerify)
);

export const selectEditSegmentHistory = (state) => (
  fromEditSegmentHistory.selectEditSegmentHistory(state.editSegmentHistory)
);

export const selectPeopleByGroupIds = (state) => (
  fromPeopleByGroupIds.selectPeopleByGroupIds(state.peopleByGroupIds)
);

export const selectWorkByGroupIds = (state) => (
  fromWorkByGroupIds.selectWorkByGroupIds(state.workByGroupIds)
);

export const selectWorkTypes = (state) => (
  fromWorkTypes.selectWorkTypes(state.workTypes)
);

export const selectGroupContactsWorks = (state) => (
  fromGroupContactsWorks.selectGroupContactsWorks(state.groupContactsWorks)
);

export const selectListLevelGeneral = (state) => (
  fromListLevelGeneral.selectListLevelGeneral(state.listLevelGeneral)
);

export const selectDraftReport = (state) => (
  fromDraftReport.selectDraftReport(state.draftReport)
);

export const selectDraftReview = (state) => (
  fromDraftReview.selectDraftReview(state.draftReview)
);

export const selectTranslatedSentence = (state) => (
  fromTranslatedSentence.selectTranslatedSentence(state.translatedSentence)
);

export const selectContactMembers = (state) => (
  fromContactMembers.selectContactMembers(state.contactMembers)
);

export const selectFriendInvitation = (state) => (
  fromFriendInvitation.selectFriendInvitation(state.friendInvitation)
);

export const selectGroupByName = (state) => (
  fromGroupByName.selectGroupByName(state.groupByName)
);

export const selectTextImageUploads = (state) => (
  fromTextImageUploads.selectTextImageUploads(state.textImageUploads)
);

export const selectTextImageGroups = (state) => (
  fromTextImageGroups.selectTextImageGroups(state.textImageGroups)
);

export const selectWorkAddSettings = (state) => (
  fromWorkAddSettings.selectWorkAddSettings(state.workAddSettings)
);

export const selectTextStyleGroups = (state) => (
  fromTextStyleGroups.selectTextStyleGroups(state.textStyleGroups)
);

export const selectTextStyleFonts = (state) => (
  fromTextStyleFonts.selectTextStyleFonts(state.textStyleFonts)
);

export const selectWorkSegmentUploadProgress = (state) => (
  fromWorkSegmentUploadProgress.selectWorkSegmentUploadProgress(state.workSegmentUploadProgress)
);


export const selectHtmlCharacterEntities = (state) => (
  fromHtmlCharacterEntities.selectHtmlCharacterEntities(state.htmlCharacterEntities)
);

export const selectHtmlCharacterOptions = (state) => (
  fromHtmlCharacterEntities.selectHtmlCharacterOptions(state.htmlCharacterEntities)
);

export const selectAssessment = (state) => (
  fromAssessment.selectAssessment(state.assessment)
);

export const selectAssessments = (state) => (
  fromAssessments.selectAssessments(state.assessments)
);

export const selectAssessmentCorrect = (state) => (
  fromAssessmentCorrect.selectAssessmentCorrect(state.assessmentCorrect)
);

export const selectAssessmentCorrectSameAllStudents = (state) => (
  fromAssessmentCorrectSameAllStudents.selectAssessmentCorrectSameAllStudents(state.assessmentCorrectSameAllStudents)
);

export const selectAssessmentCorrectSummary = (state) => (
  fromAssessmentCorrectSummary.selectAssessmentCorrectSummary(state.assessmentCorrectSummary)
);

export const selectAssessmentPendingEssay = (state) => (
  fromAssessmentPendingEssay.selectAssessmentPendingEssay(state.assessmentPendingEssay)
);

export const selectAssessmentQuestions = (state) => (
  fromAssessmentQuestions.selectAssessmentQuestions(state.assessmentQuestions)
);

export const selectAssessmentQuestion = (state) => (
  fromAssessmentQuestion.selectAssessmentQuestion(state.assessmentQuestion)
);

export const selectQuestionTypes = (state) => (
  fromQuestionTypes.selectQuestionTypes(state.questionTypes)
);

export const selectVoiceRecording = (state) => (
  fromVoiceRecording.selectVoiceRecording(state.voiceRecording)
);

export const selectGroupMembers = (state) => (
  fromGroupMembers.selectGroupMembers(state.groupMembers)
);

