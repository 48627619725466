import React, { useState, useEffect, useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import styles from './DropDownFloatCustom.module.css';
import * as editorService from '../../services/editor-dom'

export default function DropDownFloatCustom({ 
	children, 
	divDisplayId, 
	editorDivId,
	header,
	iconAvatar, 
	id, 
	keyIndex,
	label, 
	listOptions, 
	listWidth='150px',
	onClickForMessage = () => {}, 
	onSelect = () => {}, 
	panelAbsolute,
}) {
	const [open, setOpen] = useState()
	const [adjustedPosition, setAdjustedPosition] = useState('');
	const containerRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			let isMessage = false
			let isDropDownButton = false
			let element = event.target
			let loop = 0
			while (element && loop < 10 && !isMessage) {
				isMessage = element.classList && Array.from(element.classList).some(className => className.includes('dialogButtons'))
				isDropDownButton = element.classList && Array.from(element.classList).some(className => className.includes('ButtonDropDown'))
				element = element.nextSibling
				loop++
			}
			if (containerRef.current && (!containerRef.current.contains(event.target) || isMessage) && !isDropDownButton) {
				setOpen(false)
			}
		}
		const handleKeyDown = (event) => {
			if (event.key === 'Escape' && containerRef.current) { // ESC key
				setOpen(false)
			}
		};
		checkForAdjustPosition()
		// const editorDiv = document.getElementById(editorDivId)
		// if (editorDiv) {
		// 	editorDiv.style.userSelect = open ? 'none' : '' //this is so the drop down list will not struggle with the "cut and copy" pop-up tha tcomes up when trying to select a drop down option over a selected text behind it.
		// }

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		}
	}, [open]);


	const checkForClose = (event) => {
		event.stopPropagation()
		checkForAdjustPosition()
		const element = event && event.target
		if (element && element.innerText === 'Next') setOpen(false)
	}

	const checkForAdjustPosition = () => {
		if (open && containerRef.current) {
			const rect = containerRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;

			if (rect.right > viewportWidth) {
				setAdjustedPosition(`translateX(${viewportWidth - rect.right - 10}px)`); // Adjust to stay within the viewport
			} else {
				setAdjustedPosition(''); // Reset the position if it's fine
			}
		}		
	}

	return (
		<div onClick={checkForClose} style={{ height: '20px' }} key={keyIndex} className={styles.container}>
			<IconButton
				id={id}
				onClick={(event) => { event.stopPropagation(); setOpen(!open); checkForAdjustPosition(); }}
				size="small"
				sx={{ width: label ? '150px' :'25px', position: 'relative', top: '-3px', left: label ? '-15px' : '' }}
				aria-controls={open ? 'drop-down-list' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{iconAvatar}
				{label}
			</IconButton>	
			<div className={panelAbsolute ? styles.panelAbsolute : styles.panel} style={{ display: open ? 'block' : 'none', width: listWidth, transform: adjustedPosition}}>
				{header &&
					<div className={styles.header} onClick={() => setOpen(false)}>
						{header}
					</div>
				}
        <ul className={styles.ulList} ref={containerRef}>
					{children}
					{listOptions?.length > 0 && listOptions.map((m, i) => {
						if (m.isInstruction) {
							return <li key={i} className={styles.instructions}>{m.instructions}</li>
						} else {
							return (
								<li key={i} 
									onClick={(event) => {
										event.stopPropagation()
										onSelect(m.id, event, divDisplayId)
										setOpen(false)
										onClickForMessage(m.id)
									}}>
									{m.label}
								</li>
							)
						}
					})}
				</ul>
			</div>
		</div>
	)
}