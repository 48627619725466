import React from 'react';
import styles from './PointsDisplay.module.css';
import classes from 'classnames';
import TextDisplay from '../TextDisplay'
import InputText from '../InputText'

function PointsDisplay(props) {
  const {
    assessmentQuestionId,
    className,
    includeCorrectControls,
    label = 'Points',
    onBlurScore,
    pointsPossible,
    score,
    setScore,
    studentPersonId,
  } = props

  const handleSetScore = (event) => {
    setScore(event.target.value, studentPersonId, assessmentQuestionId)
  }

  const handleBlurScore = (event) => {
    onBlurScore(event.target.value, studentPersonId, assessmentQuestionId)
  }

	return (
    <div className={className}>      
      <TextDisplay label={label} text={includeCorrectControls
        ? <div className={styles.row}>
            <InputText size={'super-short'}
              label={''}
              value={score === 0 ? '0' : score || '' }
              numberOnly={true}
              maxLength={6}
              alignTextRight
              biggerTextSize
              onChange={handleSetScore}
              onBlur={handleBlurScore}/>

            <div className={classes(styles.littleTop, styles.row)}>
              <div className={styles.plainText}>out of</div>
              <div className={styles.pointsText}>{pointsPossible}</div>
            </div>
          </div>
        : <div className={(styles.littleTop, styles.row)}>
          <div>{Math.round(score * 10) / 10 || '0'}</div>
          <div className={styles.plainText}>out of</div>
          <div className={styles.pointsText}>{pointsPossible}</div>
        </div>
      } />
    </div>
	)
};


export default PointsDisplay