import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import styles from './AssessmentCorrectSummaryView.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import EditTableFreezeLeft from '../../../components/EditTableFreezeLeft'
import EditTableStickyLeft from '../../../components/EditTableStickyLeft'
import TextDisplay from '../../../components/TextDisplay'
import Icon from '../../../components/Icon'
import OneFJefFooter from '../../../components/OneFJefFooter'
import classes from 'classnames'
import { createInfoToastAuto } from '../../../services/queryClient.js'

function AssessmentCorrectSummaryView(props) {
  const {
    assignmentId,
    assessmentId,
    correctSummary,
    assessmentQuestions,
  } = props

  const navigate = useNavigate()
  const componentRef = useRef(null)

  const [finalHeadings, setFinalHeadings] = useState([])
  const [finalData, setFinalData] = useState([])

  const sendToCorrectView = (studentPersonId) => {
    navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${studentPersonId}`)
  }

  const handlePathLink = (path) => path && navigate(path)

  const setHeaderLabel = (question) => {
    return (
      <div className={styles.rowLink}>
        <div className={styles.notBold}>{`${question.sequence} - ${question.questionTypeName}`}</div>
        {question.questionTypeCode === 'ESSAY' &&
          <div className={styles.rowSpaceLeft}>
            <Icon pathName={'pencil0'} fillColor='white' />
            <div className={styles.offWhiteText}>edit all</div>
          </div>
        }
      </div>
    )
  }

  const hasCompletedAssessment = (personId) => {
    const hasPersonCompleted = correctSummary?.studentsCompleted?.length > 0 && correctSummary?.studentsCompleted.filter(m => m.id === personId)[0]
    return hasPersonCompleted && hasPersonCompleted.id ? true : false
  }

  useEffect(() => {
    let headings = [{ label: '', tightText: true }]

    assessmentQuestions?.length > 0 && assessmentQuestions.forEach(m =>
      headings.push({
        label: setHeaderLabel(m),
        pathLink: m.questionTypeCode === 'ESSAY'
          ? `/editReview/${correctSummary.workId}/${correctSummary.chapterId}/${correctSummary.languageId}/${m.assessmentQuestionId}`
          : `/assessmentCorrectSameAll/${assignmentId}/${assessmentId}/${m.assessmentQuestionId}`,
        tightText: true
      })
    )

    let data = []

    correctSummary?.allGroupMembers?.length > 0 && correctSummary.allGroupMembers.forEach(member => {
      let row = [{
        cellColor: 'background',
        value: <div className={styles.rowSpace}>
          <div className={styles.row}>
            <div 
              className={classes(styles.link, styles.row)} 
              onClick={hasCompletedAssessment(member.id)
                ? () => sendToCorrectView(member.id)
                : () => createInfoToastAuto('<div>The chosen student does not have a completed assessment.</div>')
              }
            >
              {member.label}
            </div>
            <div className={styles.moreLeft}>{correctSummary?.studentsCompleted?.length > 0 && correctSummary.studentsCompleted.filter(f => f.id === member.id).sum}</div>
          </div>
        </div>
      }]
      const completed = correctSummary?.studentsCompleted?.length > 0 && correctSummary.studentsCompleted.filter(d => d.id === member.id)[0]
      if (completed && completed.corrected?.length > 0) {
        const corrected = [...completed.corrected].sort((a, b) => a.sequence - b.sequence)
        corrected.forEach(c => {
          let score = c.score
          row.push({ 
            value: score ? score == 0 ? '0' : score : '--', 
            clickFunction: hasCompletedAssessment(c.personId)
              ? () => navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${c.personId}`)
              : () => createInfoToastAuto('<div>The chosen student does not have a completed assessment.</div>')
          })
        })
      }
      if (!completed?.corrected?.length) {
        assessmentQuestions?.length > 0 && assessmentQuestions.forEach(m => {
          row.push({ value: '--' })
        })
      }
      data.push(row)
    })

    setFinalHeadings(headings)
    setFinalData(data)
  }, [assessmentQuestions])


  console.log('finalHeadings', finalHeadings)
  console.log('finalData', finalData)  

  return (
    <div className={styles.container}>
      <div className={classes(styles.mainDiv, styles.backgroundTopSpace)}>
        <div className={classes(globalStyles.pageTitle, styles.moreMarginBottom, styles.whiteText)}>
          Assessment Correction Summary
        </div>
        <div className={styles.row}>
          <TextDisplay label='Assignment' text={correctSummary.assignmentTitle} className={styles.whiteText} />
        </div>
        <div className={styles.tableMargin} ref={componentRef}>
          <EditTableStickyLeft />
          <EditTableFreezeLeft
            data={finalData}
            firstColumnClass={styles.firstColumnClass}
            headings={finalHeadings}
            labelClass={styles.tableLabelClass}
            sendToReport={handlePathLink} />
        </div>
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default AssessmentCorrectSummaryView
