import React from 'react'
import styles from './AssessmentTrueFalse.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import CheckboxToggle from '../../CheckboxToggle'
import Icon from '../../Icon'
import ImageDisplay from '../../ImageDisplay'
import AudioDisplay from '../../AudioDisplay'
import QuestionLabel from '../../QuestionLabel'
import PointsDisplay from '../../PointsDisplay'
import classes from 'classnames'

function AssessmentTrueFalse(props) {
  const {
    assessmentCorrect,
    assessmentQuestionId,
    assignmentId,
    bigTextDisplay,
    className = "",
    includeCorrectControls,
    onBlurScore,
    onClick,
    personId,
    question,
    removeQuestionFileOpen,
    removeQuestionRecordingOpen,
    removeSolutionFileOpen,
    removeSolutionRecordingOpen,
    score,
    setScore,
  } = props

  const sendAssessmentAnswer = (value) => {
    if (onClick) onClick(personId, question.assessmentQuestionId, value, assignmentId)
  }

  let correct = (assessmentCorrect?.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}

  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'True or False'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {correct && correct.assessmentLearnerAnswerId
          ? correct.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <PointsDisplay 
        assessmentQuestionId={assessmentQuestionId}
        className={styles.littleRight} 
        includeCorrectControls={includeCorrectControls} 
        onBlurScore={onBlurScore}
        pointsPossible={question.pointsPossible} 
        score={score} 
        setScore={setScore} 
        studentPersonId={question?.learnerAnswer?.personId} />
      {question.questionRecordingFileUrl &&
        <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'}
          controls="controls" className={styles.audioLeftQuestion}
          isSmall={true} isOwner={question.isOwner}
          deleteFunction={(event) => removeQuestionRecordingOpen(event, question.assessmentQuestionId, question.questionRecordingFileUploadId)} />
      }
      {question.questionFileUploadId &&
        <ImageDisplay linkText={''} url={question.questionFileUrl}
          isOwner={question.isOwner}
          deleteFunction={() => removeQuestionFileOpen(question.assessmentQuestionId, question.questionFileUploadId)} />
      }
      <div className={styles.radioLeft}>
        <CheckboxToggle
          label={'True?'}
          checked={(question.isOwner && question.isCorrect) || question.learnerAnswer?.learnerAnswer === 'true' || false}
          onClick={!!onClick ? () => sendAssessmentAnswer(!question.learnerAnswer?.learnerAnswer) : () => { }}
          personId={personId}
          labelClass={classes((bigTextDisplay ? globalStyles.bigText : ''),
            correct.isSubmitted
              ? question.isCorrect
                ? styles.labelCorrect
                : styles.labelWrong
              : styles.label
          )} />
        <CheckboxToggle
          label={'False?'}
          checked={(question.isOwner && question.isCorrect) || (question.learnerAnswer && question.learnerAnswer?.learnerAnswer === 'false') || false}
          onClick={onClick ? () => sendAssessmentAnswer(!question.learnerAnswer?.learnerAnswer) : () => { }}
          personId={personId}
          labelClass={classes((bigTextDisplay ? globalStyles.bigText : ''),
            correct.isSubmitted
              ? question.isCorrect
                ? styles.labelCorrect
                : styles.labelWrong
              : styles.label
          )} />
      </div>
      {(question.solutionText || question.solutionFileUrl || question.solutionRecordingFileUrl) && (question.isOwner || (correct && correct.assessmentId)) &&
        <div className={styles.muchLeft}>
          <div className={styles.row}>
            <div className={styles.headerLabel}>Solution:</div>
            {!(correct && correct.assessmentId) &&
              <div className={globalStyles.instructions}>
                (After the quiz is corrected, this explanation or picture will be displayed)
              </div>
            }
          </div>
          <div className={styles.solutionText}>{question.solutionText}</div>
          {question.solutionRecordingFileUrl &&
            <AudioDisplay src={question.solutionRecordingFileUrl}
              preload={'auto'} controls="controls"
              className={styles.audioLeftQuestion}
              isSmall={true} isOwner={question.isOwner}
              deleteFunction={() => removeSolutionRecordingOpen(question.assessmentQuestionId, question.solutionRecordingFileUploadId)} />
          }
          {question.solutionFileUrl && question.solutionFileUploadId &&
            <ImageDisplay linkText={''} url={question.solutionFileUrl}
              isOwner={question.isOwner}
              deleteFunction={() => removeSolutionFileOpen(question.assessmentQuestionId, question.solutionFileUploadId)} />
          }
        </div>
      }
    </div>
  )
}


export default AssessmentTrueFalse