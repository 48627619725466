import React, { useRef } from 'react'
import styles from './VoiceRecordingModal2.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
//import {ModalContainer, ModalDialog} from '../react-modal-dialog/lib/index.js'
import TextDisplay from '../TextDisplay'
import ButtonWithIcon from '../ButtonWithIcon'
import classes from 'classnames'
import { useMediaQuery } from "react-responsive"

function VoiceRecordingModal2(props) {
  const { 
    blobUrl, 
    handleClose, 
    isOpen,
    label, 
    onTheAir, 
    startRecording, 
    stopRecording, 
    submitFileUpload, 
    title, 
  } = props
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={classes(globalStyles.pageTitle, styles.whiteText)}>
            Voice Recording
          </div>
          <div className={styles.containerName}>
            <TextDisplay label={label} text={title} />
          </div>
          <audio controls={"controls"} src={blobUrl} type={"audio/mpeg"}>
            This browser does not support this audio control
          </audio>
          <div className={styles.rowButtons}>
            <ButtonWithIcon label={'Start'} icon={'microphone'} onClick={startRecording} className={styles.submitButton} disabled={onTheAir} />
            <ButtonWithIcon label={'Stop'} icon={'stop_circle'} onClick={stopRecording} className={styles.submitButton} changeRed={true} disabled={!onTheAir} />
            <div className={styles.moreLeft}>
              <ButtonWithIcon label={'Save'} icon={'checkmark_circle'} onClick={submitFileUpload} disabled={!blobUrl || onTheAir} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoiceRecordingModal2