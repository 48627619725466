import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import AssessmentCorrectView from '../views/Assessment/AssessmentCorrectView'
import * as actionAssessmentCorrect from '../actions/assessment-correct'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionGroupFileTree from '../actions/group-file-tree-sub-contents.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionWorks from '../actions/works.js'
//import * as actionMyVisitedPages from '../actions/my-visited-pages.js'
import {
  selectMe,
  selectAssessment,
  selectAssessmentCorrect,
  selectAssessmentQuestions,
  selectGroupMembers,
  selectListLevelGeneral,
} from '../store.js'

const mapStateToProps = (state, props) => {
  let me = selectMe(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  let assessmentCorrect = selectAssessmentCorrect(state)
  let assessment = selectAssessment(state)
  let correctionTypeFilter = [{
    label: 'All',
    id: 'all',
  },
  {
    label: 'Pending essays',
    id: 'pendingEssays',
  },
  {
    label: 'Wrong answers',
    id: 'wrongAnswers',
  },
  {
    label: 'Correct answer',
    id: 'correctAnswers',
  },
  ]

  let questionTypeFilter = [{
    label: 'All',
    id: 'all',
  },
  {
    label: 'Essays',
    id: 'ESSAY',
  },
  {
    label: 'Multiple Choice',
    id: 'MULTIPLECHOICE',
  },
  {
    label: 'Multiple Answer',
    id: 'MULTIPLEANSWER',
  },
  {
    label: 'True or False',
    id: 'TRUEFALSE',
  },
  ]

  return {
    assessment,
    assessmentCorrect: assessmentCorrect,
    assessmentQuestions,
    correctionTypeFilter,
    isAuthor: assessment.personId === me.personId,
    groupMembers: selectGroupMembers(state),
    langCode: me.langCode,
    listLevelGeneral: selectListLevelGeneral(state),
    personId: me.personId,
    questionTypeFilter,
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  assessmentQuestionsInit: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.init(personId, studentPersonId, assessmentId, assignmentId)),
  getCorrectedAssessment: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentCorrect.getCorrectedAssessment(personId, studentPersonId, assessmentId, assignmentId)),
  retakeTest: (personId, assignmentId, assessmentId, runFunction) => dispatch(actionAssessmentCorrect.retakeTest(personId, assignmentId, assessmentId, runFunction)),
  teacherEssayResponse: (personId, teacherResponse) => dispatch(actionAssessmentCorrect.teacherEssayResponse(personId, teacherResponse)),
  //setPenspringTransfer: (personId, work) => dispatch(actionPenspringTransfer.setPenspringTransfer(personId, work)),
  updateTeacherAssessmentLearnerAnswer: (personId, studentPersonId, assessmentQuestionId, score, assignmentId) => dispatch(actionAssessmentQuestions.updateTeacherAssessmentLearnerAnswer(personId, studentPersonId, assessmentQuestionId, score, assignmentId)),
  setLocalScore: (studentPersonId, assessmentQuestionId, score) => dispatch(actionAssessmentCorrect.setLocalScore(studentPersonId, assessmentQuestionId, score)),
  clearAssessmentQuestion: () => dispatch(actionAssessmentQuestions.clearAssessmentQuestion()),
  clearAssessmentCorrect: () => dispatch(actionAssessmentCorrect.clearAssessmentCorrect()),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  getGroupMembers: (groupId, assignmentId, displayOnlyCompletedAssignment) => dispatch(actionGroupFileTree.getGroupMembers(groupId, assignmentId, displayOnlyCompletedAssignment)),
  //    setMyVisitedPage: (personId, myVisitedPage) => dispatch(actionMyVisitedPages.setMyVisitedPage(personId, myVisitedPage)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    assessmentQuestionsInit,
    getAssessment,
    getCorrectedAssessment,
    getGroupMembers,
    getListLevelGeneral,
    personId,
  } = props

  const params = useParams()

  useEffect(() => {
    getAssessment(personId, params.assessmentId)
    getCorrectedAssessment(personId, params.studentPersonId, params.assessmentId, params.assignmentId)
    assessmentQuestionsInit(personId, params.studentPersonId, params.assessmentId, params.assignmentId)
    getGroupMembers(null, params.assignmentId, true) //true is displayOnlyCompletedAssignment
    getListLevelGeneral()
    //props.location && props.location.pathname && setMyVisitedPage(personId, { path: props.location.pathname, description: `Correct Assessment (${studentFullName})` })
  }, [personId, params.studentPersonId, params.assessmentId, params.assignmentId])

  return <AssessmentCorrectView {...props}
    assessmentId={params.assessmentId}
    assignmentId={params.assignmentId}
    studentPersonId={params.studentPersonId} />
}

export default storeConnector(Container)
