import React from 'react';
import styles from './EditListChoice.module.css';
import DropDownFloatCustom from '../DropDownFloatCustom'
import ButtonDropDown from '../ButtonDropDown'

function EditListChoice(props) {
  const {
    editOptions = [],
    handleSetEditChosen,
    editChosen,
    keyIndex
  } = props;

  // const handleEditMove = (direction) => {
  //   let index = -1
  //   editOptions.forEach((m, i) => {
  //     if (m.id === Number(editChosen)) index = i
  //   })

  //   if (direction === 'PREV') {
  //     if (index < 1) {
  //       handleSetEditChosen(0, '')
  //     } else {
  //       handleSetEditChosen(editOptions[index - 1].id, editOptions[index - 1].label)
  //     }
  //   } else {
  //     if (index >= editOptions.length - 1) {
  //       handleSetEditChosen(editOptions[editOptions.length - 1].id, editOptions[editOptions.length - 1].label)
  //     } else {
  //       handleSetEditChosen(editOptions[index + 1].id, editOptions[index + 1].label)
  //     }
  //   }
  // }

  const getChoiceLabel = () => {
    let choiceOptionIndex = editOptions?.length > 0 ? editOptions.findIndex(m => m.id === editChosen) : ''
    if (choiceOptionIndex || choiceOptionIndex === 0) choiceOptionIndex++
    if (!choiceOptionIndex) choiceOptionIndex = 0
    return choiceOptionIndex + ' / ' + (editOptions?.length || 0)
  }

  return (
    <div className={styles.container} key={keyIndex} style={{position: 'relative', zIndex: '1030'}}>
      <div className={styles.container} title={'Edit counts and locations'}>
        <DropDownFloatCustom key={'Edits'}
          iconAvatar={<ButtonDropDown label={'Edits'} />}
          panelAbsolute
          header={<div>Edit counts and locations</div>}
          onSelect={(id) => handleSetEditChosen(id, id ? editOptions.filter(m => m.id === id)[0].label : '')}
          listOptions={editOptions}
          showChoiceLabel
          choiceLabel={getChoiceLabel()}
          labelLeftpx={'-4px'} />
        <div className={styles.count}>{editOptions?.length || 0}</div>
      </div>
    </div>
  )
}

export default EditListChoice