import React from "react"
import styles from "./EditTableStickyLeft.module.css"
import classes from 'classnames'

function EditTableStickyLeft() {
  const data = [
    { id: 'Nikola person', name: "11", age: 25, city: "--", name2: "--", age2: 25, city2: "4" },
    { id: 'So,epm ;lksdfa person', name: "--", age: 30, city: "3", name2: "2", age2: 30, city2: "1" },
    { id: '98uasdjk  kj asf person', name: "--", age: 35, city: "2", name2: "4", age2: 35, city2: "3" },
    { id: 'a rather long one person', name: "2", age: 40, city: "6", name2: "8", age2: 40, city2: "99" },
    { id: 'short person', name: "--", age: 45, city: "--", name2: "--", age2: 45, city2: "3" },
  ]

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={classes(styles.stickyColumn, styles.narrow)}></th>
            <th className={styles.rotate}><div>NAME really long thing</div></th>
            <th className={styles.rotate}><div>AGE really long thing</div></th>
            <th className={styles.rotate}><div>CITY really long thing</div></th>
            <th className={styles.rotate}><div>NAME2 really long thing</div></th>
            <th className={styles.rotate}><div>AGE2 really long thing</div></th>
            <th className={styles.rotate}><div>CITY2 really long thing</div></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td className={classes(styles.stickyColumn, styles.narrow)}>{row.id}</td>
              <td style={{textAlign: 'center'}}>{row.name}</td>
              <td style={{textAlign: 'center'}}>{row.age}</td>
              <td style={{textAlign: 'center'}}>{row.city}</td>
              <td style={{textAlign: 'center'}}>{row.name2}</td>
              <td style={{textAlign: 'center'}}>{row.age2}</td>
              <td style={{textAlign: 'center'}}>{row.city2}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default EditTableStickyLeft
