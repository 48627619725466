import * as types from './actionTypes';
import {apiHost} from '../api_host.js';

export const getWorkEditReview = (personId, workId) => {
    //This has changed to handle discussion entries, test/quizzes, and general multiple homework with essays. It can return one workSummary, but now it returns many so it will be an array in every case.
    //ToDo do we need the languageId here to be sent in here for editReview? Maybe not here.
    return dispatch => {
        dispatch({type: types.FETCHING_RECORD, payload: {workEditReview: true} });
        return fetch(`${apiHost}ebi/work/editReview/${personId}/${workId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            dispatch({ type: types.WORK_EDIT_REVIEW, payload: response });
        })
    }
}

export const getWorkEditorAccess = (personId, workSearch='', id='') => {
    return dispatch => { //Help ToDo: If this has been workId, it needs to change to chapterId
        fetch(`${apiHost}ebi/work/editorAccess/${personId}/${workSearch}/${id}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json()
              } else {
                  const error = new Error(response.statusText)
                  error.response = response
                  throw error
              }
          })
          .then(response => {
              dispatch({ type: types.WORK_EDITOR_ACCESS_INIT, payload: response })
          })
    }
}


export const setLocalScoreWork = (workId, score) => {
  return dispatch => {
    dispatch({ type: types.WORK_ASSESSMENT_SCORE_UPDATE, payload: { workId, score } });
  }
}

