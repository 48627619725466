import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './AssessmentEssay.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import classes from 'classnames'
import TextDisplay from '../../TextDisplay'
import ImageDisplay from '../../ImageDisplay'
import AudioDisplay from '../../AudioDisplay'
import Icon from '../../Icon'
import QuestionLabel from '../../QuestionLabel'
import PointsDisplay from '../../PointsDisplay'
import penspringSmall from '../../../assets/penspring_small.png'
import { guidEmpty } from '../../../utils/GuidValidate.js'
import * as editorService from '../../../services/editor-dom'
import { createInfoToastAuto } from '../../../services/queryClient.js'

function AssessmentEssay(props) {
  const {
    assessmentQuestionId,
    assessmentCorrect,
    bigTextDisplay,
    className = "",
    createWorkInAssessment,
    includeCorrectControls,
    isAuthor,
    isMobile,
    isSubmitted,
    listLevelGeneral,
    nameKey,
    onBlurScore,
    personId,
    removeQuestionFileOpen,
    removeQuestionRecordingOpen,
    removeSolutionFileOpen,
    removeSolutionRecordingOpen,
    score,
    setScore,
  } = props

  const navigate = useNavigate()

  const [entry, setEntry] = useState({
    essayResponse: '',
    score: '',
    isCorrect: '',
  })
  const [correct, setCorrect] = useState({})
  const [errorScore, setErrorScore] = useState()
  const [question, setQuestion] = useState({})

  useEffect(() => {
    const workSummary = question?.learnerAnswer?.workSummary
    if (workSummary) {
      editorService.setSegments({
        addChapterListLevels: () => {},
        chapterId: workSummary.chapterId_current,
        chapterListLevels: [],
        chosenTab: null,
        divDisplayId: editorService.getEditorDivId(workSummary.chapterId_current),
        editorName: {firstName: workSummary.firstName, lastName: workSummary.lastName },
        edits: workSummary.editSegments,
        isAuthor: true,
        isTranslation:false,
        listLevelGeneral,
        personId,
        segments: workSummary.workSegments,
        showEditIcons: true,
        tabsData: [],
        workSummary,
        removeEmptyParagraphs: true,
      })
    }
  }, [question])

  useEffect(() => {
    setQuestion(props.question)
  }, [props.question])

  useEffect(() => {
    let correct = (assessmentCorrect?.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}
    if (assessmentCorrect !== props.assessmentCorrect) {
      setEntry({
        essayResponse: isAuthor && assessmentCorrect ? assessmentCorrect.teacherEssayResponse : assessmentCorrect ? assessmentCorrect.learnerAnswer : '',
        score: correct && correct.score,
        isCorrect: correct && correct.isCorrect,
      })
    }
    setCorrect(correct)
  }, [props.assessmentCorrect])

  const sendToEditReviewView = () => {
    const send = () => navigate(`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`)
    createInfoToastAuto('<div>Do you want to view or edit this text in penspring?</div>', send)
  }

  return (
    <div className={classes(className, styles.container)} key={nameKey}>
      <QuestionLabel label={'Essay'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {correct && correct.assessmentLearnerAnswerId && !correct.pendingCorrection
          ? correct.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true} className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true} className={styles.icon} />
          : ''
        }
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <PointsDisplay 
        assessmentQuestionId={assessmentQuestionId}
        includeCorrectControls={includeCorrectControls} 
        onBlurScore={onBlurScore}
        pointsPossible={question.pointsPossible} 
        score={score}
        setScore={setScore} 
        studentPersonId={question?.learnerAnswer?.personId} />
      {question.questionRecordingFileUrl &&
        <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'}
          controls="controls" className={styles.audioLeftQuestion}
          isSmall={true} isOwner={question.isOwner}
          deleteFunction={(event) => removeQuestionRecordingOpen(event, question.assessmentQuestionId, question.questionRecordingUploadId)} />
      }
      {question.questionFileUploadId &&
        <ImageDisplay linkText={''} url={question.questionFileUrl}
          isOwner={question.isOwner}
          deleteFunction={() => removeQuestionFileOpen(question.assessmentQuestionId, question.questionFileUploadId)} />
      }
      {isSubmitted &&
        <TextDisplay label={'Student essay'}
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.staticText)}
          text={question.learnerAnswer && question.learnerAnswer.learnerAnswer === 'PENSPRING'
            ? question.learnerAnswer && (!question.learnerAnswer.workId || question.learnerAnswer.workId === guidEmpty)
              ? <div className={styles.noAnswer}>no answer</div>
              : <Link 
                  to={`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`}
                  className={classes(styles.link, styles.row)}
                  target={'_penspring'}>
                  Review this essay in
                <img
                  className={classes(styles.penspringLogo, styles.pointer)}
                  src={penspringSmall} alt="penspring" />
              </Link>
            : question && question.learnerAnswer && question.learnerAnswer.learnerAnswer
          } />
      }
      {correct.teacherEssayResponse &&
        <div>
          <TextDisplay label={'Teacher response'}
            text={(correct && correct.teacherEssayResponse) ||
              <div className={styles.noAnswer}>no answer</div>}
            className={styles.staticText} />
          <TextDisplay label={'Score'}
            text={correct && correct.score}
            className={styles.staticText} />
        </div>
      }
      {/* {!correct.teacherEssayResponse && (!isSubmitted || (isSubmitted && isAuthor)) && */}
        <div className={styles.editorDiv}>
          {question?.learnerAnswer?.workSummary?.chapterId_current && 
            <div id={editorService.getEditorDivId(question.learnerAnswer.workSummary.chapterId_current)}
              style={{ marginTop: isMobile ? '35px' : '', marginLeft: isMobile ? '0px' : '' }}
              contentEditable='false'
              spellCheck={'true'}
              onMouseUp={sendToEditReviewView} />
          }
          <div className={classes(styles.row, styles.moreLeft)}>
            <div className={styles.penspring}>
              {isAuthor
                ? question.learnerAnswer && question.learnerAnswer.isSubmitted
                ? <Link 
                      to={`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`}
                      className={classes(styles.link, styles.row)}
                      target={'_penspring'}>
                    Edit the student's essay in
                    <img
                      className={classes(styles.penspringLogo, styles.pointer)}
                      src={penspringSmall} alt="penspring" />
                  </Link>
                  : <div className={styles.row}>
                      The students' essays will be editable in
                      <img 
                        className={styles.penspringLogo} src={penspringSmall}
                        alt="penspring" />
                    </div>
                : question && question.learnerAnswer && question.learnerAnswer.workId && question.learnerAnswer.workId !== guidEmpty
                  ? <Link 
                        to={`/editReview/${question.learnerAnswer.workId}/${question.learnerAnswer.chapterId}/${question.learnerAnswer.languageId}/${question.assessmentQuestionId}`}
                        className={classes(styles.link, styles.row)}
                        target={'_penspring'}>
                        Review this essay in
                      <img
                        className={classes(styles.penspringLogo, styles.pointer)}
                        src={penspringSmall} alt="penspring" />
                    </Link>
                  : <div 
                        onClick={question.isOwner ? () => {} : () => createWorkInAssessment(question.assessmentQuestionId) }
                        className={classes(styles.link, styles.row)}
                        target={'_penspring'}>
                        Compose this essay in
                      <img
                        className={classes(styles.penspringLogo, styles.pointer)}
                        src={penspringSmall} alt="penspring" />
                    </div>
              }
            </div>
          </div>
          { /*isAuthor &&
													<div className={classes(styles.moreBottom, styles.row)}>
															<InputText
																	id={`score`}
																	name={`score`}
																	size={"super-short"}
																	label={"Score" + (correct.pointsPossible ? ` (possible: ${correct.pointsPossible})` : '')}
																	value={((assessmentCorrect && assessmentCorrect.score === 0) || (correct && correct.score === 0)) ? 0 : (assessmentCorrect && assessmentCorrect.score) || (correct && correct.score) || ''}
																	onChange={handleChange}
																	numberOnly={true}
																	required={true}
																	whenFilled={assessmentCorrect && assessmentCorrect.score} />

															<div className={styles.checkbox}>
																	<Checkbox
					                            id={'isCorrect'}
					                            label={'Mark this essay as correct'}
																			labelClass={styles.checkboxLabel}
					                            checked={(correct&& correct.isCorrect) || false}
					                            onClick={toggleCheckbox}
																			className={styles.button}/>
															</div>
													</div> */
          }
          {(question.solutionText || question.solutionFileUrl || question.solutionRecordingFileUrl) && (question.isOwner || (correct && correct.assessmentId)) &&
            <div>
              {!(correct && correct.assessmentId) &&
                <div className={globalStyles.instructions}>
                  After the quiz is corrected, this explanation or picture will be displayed
                </div>
              }
              <div className={styles.row}>
                <div className={classes(styles.marginRight, styles.text)}>Solution</div>
                <div className={styles.text}>{question.solutionText}</div>
              </div>
              {question.solutionRecordingFileUrl &&
                <AudioDisplay src={question.solutionRecordingFileUrl}
                  preload={'auto'} controls="controls"
                  className={styles.audioLeftQuestion}
                  isSmall={true} isOwner={question.isOwner}
                  deleteFunction={() => removeSolutionRecordingOpen(question.assessmentQuestionId, question.questionRecordingUploadId)} />
              }
              {question.solutionFileUrl && question.solutionFileUploadId &&
                <ImageDisplay linkText={''} url={question.solutionFileUrl}
                  isOwner={question.isOwner}
                  deleteFunction={() => removeSolutionFileOpen(question.assessmentQuestionId, question.solutionFileUploadId)} />
              }
            </div>
          }
        </div>
      {/* } */}
    </div>
  )
}

export default AssessmentEssay