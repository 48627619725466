import React, { useState, useEffect, useRef } from 'react'
import { apiHost } from '../../../api_host.js'
import axios from 'axios'
import styles from './AssessmentItemModal.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import classes from 'classnames'
import InputDataList from '../../InputDataList'
import TextDisplay from '../../TextDisplay'
import InputText from '../../InputText'
import MessageModal from '../../MessageModal'
import SelectSingleDropDown from '../../SelectSingleDropDown'
import InputTextArea from '../../InputTextArea'
import ImageDisplay from '../../ImageDisplay'
import AssessmentEssayKeyword from '../AssessmentEssayKeyword'
import AssessmentAnswerVariation from '../AssessmentAnswerVariation'
import AssessmentMatching from '../AssessmentMatching'
import FileUploadModalWithCrop from '../../FileUploadModalWithCrop'
import VoiceRecordingModal2 from '../../VoiceRecordingModal2'
import ButtonWithIcon from '../../ButtonWithIcon'
import RadioGroup from '../../RadioGroup'
import Checkbox from '../../Checkbox'
import AudioDisplay from '../../AudioDisplay'
import Icon from '../../Icon'
import { guidEmpty } from '../../../utils/GuidValidate.js'
import MicRecorder from 'mic-recorder-to-mp3'
import { useMediaQuery } from "react-responsive"
const recorder = new MicRecorder({ bitRate: 128 })

const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

function AssessmentItemModal(props) {

  const {
    assessment,
    assessmentId,
    assessmentItem,
    className,
    gradingType,
    handleClose,
    handleRemoveFileOpen,
    handleRemoveSolutionFileOpen,
    handleRemoveQuestionFileOpen,
    handleSubmit,
    isAuthor,
    isOpen,
    personId,
    questionTypes,
    removeAnswerFileOpen,
    removeAnswerOption,
    removeAssessmentQuestionAnswerRecording,
    removeAssessmentQuestionQuestionRecording,
    removeAssessmentQuestionSolutionRecording,
    removeToMatchFileOpen,
    standards = [],
  } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const imageViewer = useRef(null);
  const imageViewerSolution = useRef(null);
  const questionFile = useRef(null);
  const solutionFile = useRef(null);
  const containerRef = useRef(null);
  
  const [answerIndex, setAnswerIndex] = useState()
  const [answerOptions, setAnswerOptions] = useState()
  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [blobUrl, setBlobUrl] = useState()
  const [deleted_fileUploadId, setDeleted_fileUploadId] = useState()
  const [errors, setErrors] = useState({
    questionTypeCode: '',
    pointsPossible: '',
    learnerOutcomeList: '',
    questionText: '',
    answers: '',
    correctAnswer: '',
    keywordPhrase: '',
    keywordCountAccuracy: '',
  })
  
  const [errorTotalPoints, setErrorTotalPoints] = useState()
  const [file, setFile] = useState({})
  const [fileUploadId, setFileUploadId] = useState()
  const [fillInTheBlankPhrases, setFillInTheBlankPhrases] = useState()
  const [deleted_recordingFileUploadId, setDeleted_recordingFileUploadId] = useState()
  const [hasOrigUpdate, setHasOrigUpdate] = useState()
  const [isFileChosen, setIsFileChosen] = useState()
  const [isMatchingFile, setIsMatchingFile] = useState()
  const [isQuestionFile, setIsQuestionFile] = useState()
  const [isShowingFileUpload, setIsShowingFileUpload] = useState()
  const [isShowingModal_keywordCount, setIsShowingModal_keywordCount] = useState()
  const [isShowingModal_missingInfo, setIsShowingModal_missingInfo] = useState()
  const [isShowingModal_removeAnswerRecording, setIsShowingModal_removeAnswerRecording] = useState()
  const [isShowingModal_removeQuestionRecording, setIsShowingModal_removeQuestionRecording] = useState()
  const [isShowingModal_removeSolutionRecording, setIsShowingModal_removeSolutionRecording] = useState()
  const [isShowingVoiceRecording, setIsShowingVoiceRecording] = useState()
  const [isSolutionFile, setIsSolutionFile] = useState()
  const [isSubmitted, setIsSubmitted] = useState()
  const [matches, setMatches] = useState()
  const [matchingEntries, setMatchingEntries] = useState()
  const [matchingType, setMatchingType] = useState()
  const [messageInfoIncomplete, setMessageInfoIncomplete] = useState()
  const [multipleAnswerAnswers, setMultipleAnswerAnswers] = useState([])
  const [multipleAnswerType, setMultipleAnswerType] = useState()
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState()
  const [multipleChoiceCorrectAnswer, setMultipleChoiceCorrectAnswer] = useState()
  const [multipleCount, setMultipleCount] = useState()
  const [multipleMatchingAnswers, setMultipleMatchingAnswers] = useState()
  const [onTheAir, setOnTheAir] = useState()
  const [openFileAttach, setOpenFileAttach] = useState()
  const [question, setQuestion] = useState()
  const [record, setRecord] = useState()
  const [recordingFileUploadId, setRecordingFileUploadId] = useState()
  const [selectedFile_question, setSelectedFile_question] = useState()
  const [selectedFile_solution, setSelectedFile_solution] = useState()
  const [selectedRecording_question, setSelectedRecording_question] = useState()
  const [selectedRecording_solution, setSelectedRecording_solution] = useState()
  const [selectedRecording_matching, setSelectedRecording_matching] = useState()
  const [isInit, setIsInit] = useState()
  const [assessmentItemId, setAssessmentItemId] = useState()
  const [standardIds, setStandardIds] = useState()

  useEffect(() => {
    let newAnswerOptions = []
    for (let i = 0; i < matchingEntries; i++) {
      newAnswerOptions.push({ id: alpha[i], label: alpha[i] })
    }
    setAnswerOptions(newAnswerOptions)
  }, [props.multipleEntries])

  useEffect(() => {
    setMultipleChoiceAnswers(question?.answers ? question.answers : [{ answerText: '' }, { answerText: '' }, { answerText: '' }])
    setMultipleCount(question?.answers ? question.answers.length : 3)
  }, [question])

  useEffect(() => {
    let newAssessmentItem = []

    let newQuestion = assessmentItem?.assessmentQuestionId
      ? props.assessmentItem
      : {
        assessmentQuestionId: '',
        questionTypeCode: '',
        pointsPossible: '',
        learnerOutcomeList: '',
        questionText: '',
        answers: [],
        correctAnswer: '',
        keywordPhrases: [],
        keywordCountAccuracy: 0,
        answerVariations: [],
        solutionText: '',
      }
    setQuestion(newQuestion)

    if (assessmentItem?.questionTypeCode === 'FILLBLANK') blankOutWord(null, assessmentItem) //This is to set the fillBlanksChosen variable.

    // if (assessmentItem?.standards?.length > 0) {
    //   newAssessmentItem['standardIds'] = assessmentItem.standards.reduce((acc, m) => acc && acc.length ? acc.concat(m.id) : [m.id], [])
    // }
    //setAssessmentItemId({ ...assessmentItem, standardIds: assessmentItem.standards })
    setIsInit(true)

  }, [assessmentItem])


  const handleRemoveInputRecording_question = () => {

  }
  
	const handleRemoveInputRecording_solution = () => {

  }
  
	const handleRemoveInputRecording_toMatch = () => {

  }
  
  const handleRemoveInputRecording_answer = () => {

  }
  
  const toggleFileAttach = () => setOpenFileAttach(!openFileAttach)

  const changeItem = ({ target }) => {
    let newQuestion = {...question}
    if (!newQuestion) {
      newQuestion = {
        assessmentQuestionId: '',
        questionTypeCode: '',
        pointsPossible: '',
        learnerOutcomeList: '',
        questionText: '',
        answers: [],
        correctAnswer: '',
        keywordPhrases: [],
        keywordCountAccuracy: 0,
        answerVariations: [],
        solutionText: '',
      }
    }
    newQuestion[target.name] = !isNaN(target.value) ? Number(target.value) : target.value
    if (target.name === 'questionText') newQuestion['fillBlankPhrase'] = target.value
    setQuestion(newQuestion)
    setHasOrigUpdate(true)
  }

  const processForm = () => {
    let missingInfoMessage = []

    if (!question.questionTypeCode) {
      setErrors({ ...errors, questionTypeCode: 'Question type is required' })
      missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>Question type</div>
    }
    if (!question.pointsPossible && question.questionTypeCode !== 'PASSAGE') {
      setErrors({ ...errors, pointsPossible: 'Points are required' })
      missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>Points</div>
    }
    if (((question.questionTypeCode === 'FILLBLANK' && !question.questionText)
      || (!question.questionText && !selectedFile_question && !selectedRecording_question && question.questionTypeCode !== 'MATCHING'))) {
      setErrors({ ...errors, questionText: 'Question text is required' })
      missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>Question text</div>
    }
    if (question.questionTypeCode === 'ESSAY' && question.keywordPhrases && question.keywordPhrases.length > 0 && !question.keywordCountAccuracy) {
      handleKeywordCountOpen()
    }
    if (question.questionTypeCode !== 'ESSAY' && question.questionTypeCode !== 'PICTUREORAUDIO' && question.questionTypeCode !== 'PASSAGE' && question.questionTypeCode !== 'MATCHING' && question.questionTypeCode !== 'FILLBLANK') {
      let hasCorrectAnswer = false
      if (question.questionTypeCode === 'TRUEFALSE' || question.questionTypeCode === 'SINGLEENTRY') {
        hasCorrectAnswer = !question.correctAnswer || question.correctAnswer.length === 0 ? false : true
      } else {
        hasCorrectAnswer = question.answers && question.answers.length > 0 && question.answers.filter(m => m.isCorrect)[0] ? true : false
      }
      if (!hasCorrectAnswer) {
        setErrors({ ...errors, hasCorrectAnswer: 'Correct answer is required' })
        missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>Correct answer</div>
      }
    }
    if (question.questionTypeCode === 'FILLBLANK' && (!question.correctAnswer || question.correctAnswer.length === 0)) {
      setErrors({ ...errors, correctAnswer: 'Choose at least one blank' })
      missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>Choose at least one blank</div>
    }

    if (question.questionTypeCode === 'MATCHING' && validateMatching() !== '') {
      missingInfoMessage = validateMatching()
    }

    if (gradingType === 'STANDARDSRATING' && question.questionTypeCode !== 'PASSAGE' && (!question.standardIds || question.standardIds.length === 0)) {
      setErrorTotalPoints("Choose at least one blank")
      missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>At least one standard</div>
    }

    if (missingInfoMessage && missingInfoMessage.length > 0) {
      handleMissingInfoOpen(missingInfoMessage)
    } else {
      //It is possible that the keywordCountAccuracy changed by force due to the change of the keywordPhrases.  It can even be blank.
      //So we are going to force the keywordcountAccuracy here if this is an Essay assessment question.
      //This will also help to make sure that the student has a chance at the question to be graded because if the count is higher than
      //	the actual number of keywordPhrases, they will never have a chance to get 100%.
      if (question.questionTypeCode === 'ESSAY') {
        let keywordPhraseCount = question.keywordPhrases && question.keywordPhrases.length ? question.keywordPhrases && question.keywordPhrases.length : 0
        if (Number(question.keywordCountAccuracy) > keywordPhraseCount) question.keywordCountAccuracy = keywordPhraseCount
      }
      handleSubmit(question)
      clearState()
      handleClose()
    }
  }

  const clearState = () => {
    setErrorTotalPoints()
    setFile({})
    setFileUploadId()
    setFillInTheBlankPhrases()
    setDeleted_recordingFileUploadId()
    setHasOrigUpdate()
    setIsFileChosen()
    setIsMatchingFile()
    setIsQuestionFile()
    setIsShowingFileUpload()
    setIsShowingModal_keywordCount()
    setIsShowingModal_missingInfo()
    setIsShowingModal_removeAnswerRecording()
    setIsShowingModal_removeQuestionRecording()
    setIsShowingModal_removeSolutionRecording()
    setIsShowingVoiceRecording()
    setIsSolutionFile()
    setIsSubmitted()
    setMatches()
    setMatchingEntries()
    setMatchingType()
    setMessageInfoIncomplete()
    setMultipleAnswerAnswers([])
    setMultipleAnswerType()
    setMultipleChoiceAnswers()
    setMultipleChoiceCorrectAnswer()
    setMultipleCount()
    setMultipleMatchingAnswers()
    setOnTheAir()
    setOpenFileAttach()
    setQuestion({
      assessmentQuestionId: '',
      questionTypeCode: '',
      pointsPossible: '',
      learnerOutcomeList: '',
      questionText: '',
      answers: [],
      correctAnswer: '',
      keywordPhrases: [],
      keywordCountAccuracy: 0,
      answerVariations: [],
      solutionText: '',
    })
    setRecord()
    setRecordingFileUploadId()
    setSelectedFile_question()
    setSelectedFile_solution()
    setSelectedRecording_question()
    setSelectedRecording_solution()
    setSelectedRecording_matching()
    setIsInit(false)
    setAssessmentItemId()
    setStandardIds()
  }

  const validateMatching = () => {
    //1. See if there are any blanks from the highest index of the correctAnswers, questionText(s) and toMatchText(s)
    //2. See if there are any duplicate answers and provide that message.
    //3. See if there are any answers that don't have a toMatchText entry.
    let missingInfoMessage = ''
    let maxIndex = question.correctAnswer && question.correctAnswer.length - 1
    if (question.questionText && question.questionText.length > 0 && question.questionText.length - 1 > maxIndex) maxIndex = question.questionText && question.questionText.length - 1
    if (question.toMatchText && question.toMatchText.length > 0 && question.toMatchText.length - 1 > maxIndex) maxIndex = question.toMatchText && question.toMatchText.length - 1

    //1. See if there are any blanks from the highest index of the correctAnswers, questionText(s) and toMatchText(s)
    for (let i = 0; i < maxIndex; i++) {
      if (question.correctAnswer[i] || question.questionText[i] || question.toMatchText[i]) {
        if (!question.correctAnswer[i] || question.correctAnswer[i] === '' || question.correctAnswer[i] === '-1')
          missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>An answer is missing</div>
        if (!question.questionText[i] || question.questionText[i] === '' || question.questionText[i] === '-1')
          missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>A left entry is missing</div>
        if (!question.toMatchText[i] || question.toMatchText[i] === '' || question.toMatchText[i] === '-1')
          missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>A right entry is missing</div>
      }
    }

    //2. See if there are any duplicate answers and provide that message.
    let hasDuplicate = false
    for (let i = 0; i <= maxIndex; i++) {
      if (question?.correctAnswer && question.correctAnswer[i] && question.correctAnswer[i] !== '0') {
        let indexValue = question.correctAnswer[i]
        if (question.correctAnswer.indexOf(indexValue) > -1 && question.correctAnswer.indexOf(indexValue) !== i) hasDuplicate = true
      }
    }
    if (hasDuplicate) missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>Duplicate answer</div>

    //3. See if there are any answers that don't have a toMatchText entry.
    let hasNoAnswerEntry = false
    for (let i = 0; i <= maxIndex; i++) {
      if (question.correctAnswer && question.correctAnswer.length > 0 && question.correctAnswer[i] && question.correctAnswer[i] !== '0') {
        let chosenAnswerIndex = alpha.indexOf(question.correctAnswer[i])
        let indexValue = question.toMatchText[chosenAnswerIndex]
        if (!indexValue) hasNoAnswerEntry = true
      }
    }
    if (hasNoAnswerEntry) missingInfoMessage[missingInfoMessage.length] = <div className={globalStyles.moreLeft}>An answer chosen has blank text</div>

    return missingInfoMessage
  }

  const handleTrueFalse = (value) => {
    let newQuestion = {...question}
    newQuestion['correctAnswer'] = value
    setQuestion(newQuestion)
  }

  const handleMultipleChoiceAnswer = ({ target }, index) => {
    let newMultipleChoiceAnswers = [...multipleChoiceAnswers]
    if (!newMultipleChoiceAnswers[index]) {
      for (var i = 0; i <= index; i++) {
        if (!newMultipleChoiceAnswers[i]) {
          newMultipleChoiceAnswers = newMultipleChoiceAnswers?.length > 0
            ? newMultipleChoiceAnswers.concat({ sequence: i })
            : [{ sequence: i }]
        }
      }
    }
    newMultipleChoiceAnswers[index].answerText = target.value
    setQuestion({ ...question, answers: newMultipleChoiceAnswers })
    setMultipleChoiceAnswers(newMultipleChoiceAnswers)
  }

  const handleRemoveMultipleChoiceAnswer = (index) => {
    removeAnswerOption(assessmentQuestionId, index)
  }

  const handleMultipleChoiceCorrectAnswer = ({ target }) => {
    let newMultipleChoiceAnswers = [...multipleChoiceAnswers]
    //Clear all of the answers' correct setting, if any, to start with and then let the new one be chosen independently below
    newMultipleChoiceAnswers = newMultipleChoiceAnswers?.length > 0 && newMultipleChoiceAnswers.map(m => {
      m.isCorrect = false
      return m
    })
    if (!newMultipleChoiceAnswers[target.value]) {
      let index = Number(target.value)
      for (var i = 0; i <= index; i++) {
        if (!newMultipleChoiceAnswers[i]) {
          newMultipleChoiceAnswers = newMultipleChoiceAnswers?.length > 0
            ? newMultipleChoiceAnswers.concat(index === i ? { isCorrect: true, sequence: i } : { sequence: i })
            : [index === i ? { isCorrect: true, sequence: i } : { sequence: i }]
        }
      }
    } else {
      newMultipleChoiceAnswers[target.value].isCorrect = true
    }
    setQuestion({ ...question, answers: newMultipleChoiceAnswers })
    setMultipleChoiceAnswers(newMultipleChoiceAnswers)
  }

  const handleMultipleAnswerAnswer = ({ target }) => {
    let newMultipleChoiceAnswers = [...multipleAnswerAnswers]
    let index = alpha.indexOf(target.name)
    if (!newMultipleChoiceAnswers[alpha.indexOf(target.name)]) {
      for (var i = 0; i <= index; i++) {
        if (!newMultipleChoiceAnswers[i]) {
          newMultipleChoiceAnswers = newMultipleChoiceAnswers?.length > 0
            ? newMultipleChoiceAnswers.concat({ sequence: i })
            : [{ sequence: i }]
        }
      }
    }
    newMultipleChoiceAnswers[alpha.indexOf(target.name)].answerText = target.value
    newMultipleChoiceAnswers[alpha.indexOf(target.name)].sequence = index
    setQuestion({ ...question, answers: newMultipleChoiceAnswers })
    setMultipleAnswerAnswers(newMultipleChoiceAnswers)
  }

  const handleMultipleAnswerCorrectAnswer = ({ target }) => {
    let newMultipleChoiceAnswers = [...multipleAnswerAnswers]
    if (!newMultipleChoiceAnswers[target.value]) {
      let index = Number(target.value)
      for (var i = 0; i <= index; i++) {
        if (!newMultipleChoiceAnswers[i]) {
          newMultipleChoiceAnswers = newMultipleChoiceAnswers?.length > 0
            ? newMultipleChoiceAnswers.concat(index === i
              ? { isCorrect: true, sequence: i }
              : { sequence: i })
            : [index === i
              ? { isCorrect: true, sequence: i }
              : { sequence: i }]
        }
      }
    } else {
      newMultipleChoiceAnswers[target.value].isCorrect = newMultipleChoiceAnswers[target.value].isCorrect ? !newMultipleChoiceAnswers[target.value].isCorrect : true
    }
    setQuestion({ ...question, answers: newMultipleChoiceAnswers })
    setMultipleChoiceAnswers(newMultipleChoiceAnswers)
  }

  const handleMatchingCorrectAnswers = (event, index) => {
    let newQuestion = {...question}
    let correctAnswer = newQuestion?.correctAnswer ? newQuestion.correctAnswer : []
    correctAnswer[index] = event.target.value
    newQuestion.correctAnswer = correctAnswer
    setQuestion(newQuestion)
  }

  const handleMatchingQuestionText = (event, index) => {
    let newQuestion = {...question}
    let questionText = newQuestion && newQuestion.newQuestionText ? question.questionText : []
    questionText[index] = event.target.value
    newQuestion.questionText = questionText
    setQuestion(newQuestion)
  }

  const handleMatchingToMatchText = (event, index) => {
    let newQuestion = {...question}
    let toMatchText = newQuestion?.toMatchText ? newQuestion.toMatchText : []
    toMatchText[index] = event.target.value
    newQuestion.toMatchText = toMatchText
    setQuestion(newQuestion)
  }

  const removeMatchingRemoveLine = (removeIndex) => {
    let newQuestion = {...question}
    if (newQuestion.correctAnswer && newQuestion.correctAnswer.length >= removeIndex + 1) newQuestion.correctAnswer.splice(removeIndex, 1)
    if (newQuestion.questionText && newQuestion.questionText.length >= removeIndex + 1) newQuestion.questionText.splice(removeIndex, 1)
    if (newQuestion.toMatchText && newQuestion.toMatchText.length >= removeIndex + 1) newQuestion.toMatchText.splice(removeIndex, 1)
    setQuestion(newQuestion)
  }

  const incrementMultipleCount = () => {
    let newCount = multipleCount >= 3 ? multipleCount : 3
    newCount++
    setMultipleCount(newCount)
  }

  const handleAddKeywordPhrase = (keywordPhrase) => {
    let newQuestion = {...question}
    if (!newQuestion.keywordPhrases || !newQuestion.keywordPhrases.length === 0 || newQuestion.keywordPhrases.indexOf(keywordPhrase) === -1) {
      let keywordPhrases = question && newQuestion.keywordPhrases ? newQuestion.keywordPhrases : []
      keywordPhrases = keywordPhrases ? keywordPhrases.concat(keywordPhrase) : [keywordPhrase]
      newQuestion.keywordPhrases = keywordPhrases
      setQuestion(newQuestion)
    }
  }

  const handleRemoveKeywordPhrase = (keywordIndex) => {
    let newQuestion = { ...question }
    newQuestion.keywordPhrases.splice(keywordIndex, 1)
    setQuestion(newQuestion)
  }

  const handleKeywordCountOpen = () => setIsShowingModal_keywordCount(true)
  const handleKeywordCountClose = () => setIsShowingModal_keywordCount(false)
  const handleKeywordCountSave = () => {
    handleSubmit(question)
    handleClose()
  }

  const handleAddAnswerVariation = (answerVariation) => {
    let newQuestion = { ...question }
    if (!newQuestion.answerVariations || !newQuestion.answerVariations.length === 0 || newQuestion.answerVariations.indexOf(answerVariation) === -1) {
      let answerVariations = question && newQuestion.answerVariations ? newQuestion.answerVariations : []
      answerVariations = answerVariations ? answerVariations.concat(answerVariation) : [answerVariation]
      newQuestion.answerVariations = answerVariations
      setQuestion(newQuestion)
    }
  }

  const handleRemoveAnswerVariation = (variationIndex) => {
    let newQuestion = { ...question }
    newQuestion.answerVariations.splice(variationIndex, 1)
    setQuestion(newQuestion)
  }

  const handleFileUploadOpen = (isQuestionFile, answerIndex, isSolutionFile, isMatchingFile) => {
    setIsShowingFileUpload(true)
    setIsQuestionFile(isQuestionFile)
    setAnswerIndex(answerIndex)
    setIsSolutionFile(isSolutionFile)
    setIsMatchingFile(isMatchingFile)
  }
  const handleFileUploadClose = () => {
    setIsShowingFileUpload(false)
    setIsQuestionFile('')
    setAnswerIndex('')
    setIsSolutionFile('')
    setIsMatchingFile('')
  }
  const handleFileUploadSubmit = () => {
    let data = new FormData()
    data.append('file', isQuestionFile
      ? selectedFile_question
      : isSolutionFile
        ? selectedFile_solution
        : isMatchingFile
          ? question.matches && question.matches.length > 0 && question.matches[answerIndex].file
          : question.answers && question.answers.length > 0 && question.answers[answerIndex].file)

    let url = isQuestionFile
      ? `${apiHost}ebi/assessmentQuestions/addQuestionFile/${personId}/${assessmentId}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}/${encodeURIComponent(question.questionText || 'EMPTY')}`
      : isSolutionFile
        ? `${apiHost}ebi/assessmentQuestions/addSolutionFile/${personId}/${assessmentId}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}/${encodeURIComponent(question.questionText || 'EMPTY')}`
        : isMatchingFile
          ? `${apiHost}ebi/assessmentQuestions/addToMatchFile/${personId}/${assessmentId}/${answerIndex}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}`
          : `${apiHost}ebi/assessmentQuestions/addAnswerFile/${personId}/${assessmentId}/${answerIndex}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}`

    axios.post(url, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
          "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
          "Authorization": "Bearer " + localStorage.getItem("authToken"),
        }
      })
      .catch(function (error) {
        //Show error here.
      })
      .then(response => {
        let newQuestion = {...question}
        if (!newQuestion || !newQuestion.assessmentQuestionId || newQuestion.assessmentQuestionId === guidEmpty) {
          newQuestion.assessmentQuestionId = response.data.assessmentQuestionId
          setQuestion(newQuestion)
        }
      })
    handleFileUploadClose()
  }

  const startRecording = () => {
    recorder.start().then(() => {
    }).catch((e) => {
      console.error(e)
    })
    setOnTheAir(true)
  }

  const stopRecording = () => {
    recorder.stop().getMp3().then(([buffer, blob]) => {
      if (isQuestionFile) {
        setSelectedRecording_question(blob)
        setBlobUrl(window.URL.createObjectURL(blob))
        setOnTheAir(false)
      } else if (isSolutionFile) {
        setSelectedRecording_solution (blob)
        setBlobUrl(window.URL.createObjectURL(blob))
        setOnTheAir(false)
      } else if (isMatchingFile) {
        setSelectedRecording_matching(blob)
        setBlobUrl(window.URL.createObjectURL(blob))
        setOnTheAir(false)
      } else {
        if (question && (question.questionTypeCode === 'MULTIPLEANSWER')) {
          let multipleAnswerAnswers = [...multipleAnswerAnswers]
          if (!multipleAnswerAnswers[answerIndex]) {
            for (let i = 0; i <= answerIndex; i++) {
              if (!multipleAnswerAnswers[i]) {
                multipleAnswerAnswers = multipleAnswerAnswers && multipleAnswerAnswers.length > 0
                  ? multipleAnswerAnswers.concat({ sequence: i })
                  : [{ sequence: i }]
              }
            }
          }
          multipleAnswerAnswers[answerIndex].recording = blob
          setQuestion({ ...question, answers: multipleAnswerAnswers })
          setSelectedRecording_question(blob)
          setBlobUrl(window.URL.createObjectURL(blob))
          setOnTheAir(false)
          setMultipleAnswerAnswers(multipleAnswerAnswers)
        } else if (question && question.questionTypeCode === 'MULTIPLECHOICE') {
          let multipleChoiceAnswers = [...multipleChoiceAnswers]
          if (!multipleChoiceAnswers[answerIndex]) {
            for (let i = 0; i <= answerIndex; i++) {
              if (!multipleChoiceAnswers[i]) {
                multipleChoiceAnswers = multipleChoiceAnswers && multipleChoiceAnswers.length > 0
                  ? multipleChoiceAnswers.concat({ sequence: i })
                  : [{ sequence: i }]
              }
            }
          }
          multipleChoiceAnswers[answerIndex].recording = blob
          setQuestion({ ...question, answers: multipleChoiceAnswers })
          setBlobUrl(window.URL.createObjectURL(blob))
          setOnTheAir(false)
          setMultipleChoiceAnswers(multipleChoiceAnswers)
        } else if (question && question.questionTypeCode === 'MATCHING') {
          if (!isMatchingFile) {  //This would be the regular answer type (the left side)
            let multipleAnswerAnswers = [...multipleAnswerAnswers]
            if (!multipleAnswerAnswers[answerIndex]) {
              for (let i = 0; i <= answerIndex; i++) {
                if (!multipleAnswerAnswers[i]) {
                  multipleAnswerAnswers = multipleAnswerAnswers && multipleAnswerAnswers.length > 0
                    ? multipleAnswerAnswers.concat({ sequence: i })
                    : [{ sequence: i }]
                }
              }
            }
            multipleAnswerAnswers[answerIndex].recording = blob
            setQuestion({ ...question, answers: multipleAnswerAnswers })
            setBlobUrl(window.URL.createObjectURL(blob))
            setOnTheAir(false)
            setMultipleAnswerAnswers(multipleAnswerAnswers)
          } else if (isMatchingFile) { //This would be the right side of the matchingolumns.
            let multipleMatchingAnswers = Object.assign([], multipleMatchingAnswers)
            if (!multipleMatchingAnswers[answerIndex]) {
              for (let i = 0; i <= answerIndex; i++) {
                if (!multipleMatchingAnswers[i]) {
                  multipleMatchingAnswers = multipleMatchingAnswers && multipleMatchingAnswers.length > 0
                    ? multipleMatchingAnswers.concat({ sequence: i })
                    : [{ sequence: i }]
                }
              }
            }
            multipleMatchingAnswers[answerIndex].recording = blob
            setQuestion({ ...question, answers: multipleMatchingAnswers })
            setBlobUrl(window.URL.createObjectURL(blob))
            setOnTheAir(false)
            setMultipleMatchingAnswers(multipleMatchingAnswers)
          }
        }
      }
    }).catch((e) => {
      console.error(e)
    })
  }


  const handleVoiceRecordingOpen = (isQuestionFile, answerIndex, isSolutionFile, isMatchingFile, matchingType) => {
    setIsShowingVoiceRecording(true)
    setIsQuestionFile(isQuestionFile)
    setAnswerIndex(answerIndex)
    setIsSolutionFile(isSolutionFile)
    setIsMatchingFile(isMatchingFile)
    setMatchingType(matchingType)
  }
  const handleVoiceRecordingClose = () => {
    setIsShowingVoiceRecording(false)
    setIsQuestionFile('')
    setAnswerIndex('')
    setIsSolutionFile('')
    setIsMatchingFile('')
  }
  const handleVoiceRecordingSubmit = () => {
    let data = new FormData()
    data.append('audio', isQuestionFile
      ? selectedRecording_question
      : isSolutionFile
        ? selectedRecording_solution
        : isMatchingFile
          ? question.matches && question.matches.length > 0 && question.matches[answerIndex].recording
          : question.answers && question.answers.length > 0 && question.answers[answerIndex].recording)

    let url = isQuestionFile
      ? `${apiHost}ebi/assessmentQuestions/addQuestionRecording/${personId}/${assessmentId}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}/${encodeURIComponent(question.questionText || 'EMPTY')}`
      : isSolutionFile
        ? `${apiHost}ebi/assessmentQuestions/addSolutionRecording/${personId}/${assessmentId}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}/${encodeURIComponent(question.questionText || 'EMPTY')}`
        : isMatchingFile
          ? `${apiHost}ebi/assessmentQuestions/addToMatchRecording/${personId}/${assessmentId}/${answerIndex}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}`
          : `${apiHost}ebi/assessmentQuestions/addAnswerRecording/${personId}/${assessmentId}/${answerIndex}/${question.assessmentQuestionId || guidEmpty}/${question.questionTypeCode}`

    axios.post(url, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
          "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
          "Authorization": "Bearer " + localStorage.getItem("authToken"),
        }
      })
      .catch(function (error) {
        //Show error here.
      })
      .then(response => {
        let newQuestion = {...question}
        if (!newQuestion || !newQuestion.assessmentQuestionId || newQuestion.assessmentQuestionId === guidEmpty) {
          newQuestion.assessmentQuestionId = response.data.assessmentQuestionId
          setQuestion(newQuestion)
        }
      })

    handleVoiceRecordingClose()
  }

  const handleInputFile_question = (file) => {
    setSelectedFile_question(file)
    var img = imageViewer.current
    var reader = new FileReader()
    reader.onloadend = function () {
      img.src = reader.result
    }
    reader.readAsDataURL(file)
    questionFile.current.after(img)
  }

  const handleInputFile_answer = (file) => {
    let question = {...question}
    let answers = question.answers || []
    if (!answers || !answers[answerIndex]) {
      for (var i = 0; i <= answerIndex; i++) {
        if (!answerIndex[i]) {
          answers = answers && answers.length > 0
            ? answers.concat({ sequence: i })
            : [{ sequence: i }]
        }
      }
    }
    answers[answerIndex].file = file
    question.answers = answers
    setQuestion(question)
    var img = document.getElementById(`imageViewer${question.questionTypeCode}${answerIndex}`)
    var reader = new FileReader()
    reader.onloadend = function () {
      img.src = reader.result
    }
    reader.readAsDataURL(file)
    //this[`answerFile${answerIndex}`].after(img)   I don't think that this is actually being used, anyway.
  }

  const handleInputFile_toMatch = (file) => {
    let newQuestion = {...question}
    let matches = newQuestion.matches || []
    if (!matches || !matches[answerIndex]) {
      for (var i = 0; i <= answerIndex; i++) {
        if (!answerIndex[i]) {
          matches = matches && matches.length > 0
            ? matches.concat({ sequence: i })
            : [{ sequence: i }]
        }
      }
    }
    matches[answerIndex].file = file
    newQuestion.matches = matches
    setQuestion(newQuestion)
    var img = document.getElementById(`imageViewerMatch${newQuestion.questionTypeCode}${answerIndex}`)
    var reader = new FileReader()
    reader.onloadend = function () {
      img.src = reader.result
    }
    reader.readAsDataURL(file)
    //this[`toMatchFile${answerIndex}`].after(img)   This actually in the AssessmentMatching component which can't be seen from here.  I don't think that this works anyway.
  }

  const handleInputFile_solution = (file) => {
    setSelectedFile_solution(file)
    var img = imageViewerSolution.current
    var reader = new FileReader()
    reader.onloadend = function () {
      img.src = reader.result
    }
    reader.readAsDataURL(file)
    //solutionFile.current.after(img)
  }

  const handleRemoveInputFile_question = () => {
    setSelectedFile_question(null)
    var img = imageViewer.current
    img.src = ''
    questionFile.current.after(img)
  }

  const handleRemoveInputFile_answer = () => {
    let newAnswers = [...question.answers]
    newAnswers = newAnswers?.length > 0
      ? newAnswers
      : [{ answerText: '', file: {} }, { answerText: '', file: {} }, { answerText: '', file: {} }]
    newAnswers[answerIndex].file = ''
    setQuestion({ ...question, answers: newAnswers })
    var img = document.getElementById(`imageViewer${question.questionTypeCode}${answerIndex}`)
    img.src = ''
    //questionFile.after(img)
  }

  const handleRemoveInputFile_toMatch = () => {
    let newMatches = [...question.matches]
    newMatches = newMatches?.length > 0
      ? matches
      : [{ answerText: '', file: {} }, { answerText: '', file: {} }, { answerText: '', file: {} }]
    newMatches[answerIndex].file = ''
    setQuestion({ ...question, matches: newMatches })
    var img = document.getElementById(`imageViewer${question.questionTypeCode}${answerIndex}`)
    img.src = ''
    //questionFile.after(img)
  }

  const handleRemoveInputFile_solution = () => {
    setSelectedFile_solution(null)
    var img = imageViewerSolution.current
    img.src = ''
    //solutionFile.current.after(img)
  }

  const handleMissingInfoOpen = (messageInfoIncomplete) => {
    setIsShowingModal_missingInfo(true)
    setMessageInfoIncomplete(messageInfoIncomplete)
  }
  const handleMissingInfoClose = () => {
    setIsShowingModal_missingInfo(false)
    setMessageInfoIncomplete('')
  }
  const toggleAnswerNumberOnly = () => {
    let newQuestion = {...question}
    newQuestion.answerNumberOnly = !newQuestion.answerNumberOnly
    setQuestion(newQuestion)
  }

  const fillInTheBlankDisplay = () => {
    let correctAnswers = typeof question.correctAnswer === 'string'
      ? !question.correctAnswer
        ? []
        : question.correctAnswer.split(',')
      : question.correctAnswer
        ? question.correctAnswer
        : []

    correctAnswers = correctAnswers && correctAnswers.length > 0 && correctAnswers.map(m => Number(m))

    let arrayWords = question.questionText && question.questionText.split(' ')
    let result = <div className={styles.row}>
      {arrayWords && arrayWords.length > 0 && arrayWords.map((word, index) => {
        if (correctAnswers && correctAnswers.length > 0 && correctAnswers.indexOf(index) > -1) {
          return <input key={index} type={'text'} className={classes(styles.wordSpace, styles.shortTextInput)}
            onClick={() => blankOutWord(index)} />
        } else {
          return <div key={index} className={classes(styles.link, styles.wordSpace)} onClick={() => blankOutWord(index)}>
            {word}
          </div>
        }
      })}
    </div>

    return result
  }

  const blankOutWord = (index, assessIncoming) => {
    const newQuestion = assessIncoming ? assessIncoming : {...question}
    let fillBlanksChosen = typeof newQuestion.correctAnswer === 'string'
      ? !newQuestion.correctAnswer
        ? []
        : newQuestion.correctAnswer.split(',')
      : newQuestion.correctAnswer
    //If this index exists, then delete it.
    //Otherwise add it.
    if (fillBlanksChosen && fillBlanksChosen.length > 0 && fillBlanksChosen.indexOf(index) > -1) {
      fillBlanksChosen = fillBlanksChosen.filter(m => m !== index)
    } else {
      fillBlanksChosen = fillBlanksChosen && fillBlanksChosen.length > 0 ? fillBlanksChosen.concat(index) : [index]
    }
    fillBlanksChosen = fillBlanksChosen && fillBlanksChosen.length > 0 && fillBlanksChosen.map(m => !m && m !== 0 && m !== '0' ? null : Number(m))
    let fillInTheBlankPhrases = getFillInTheBlankPhrases(fillBlanksChosen)
    setFillInTheBlankPhrases(fillInTheBlankPhrases)
    setQuestion({ ...question, correctAnswer: fillBlanksChosen })
  }

  const getFillInTheBlankPhrases = (fillBlanksChosen) => {
    let arrayWords = question.questionText && question.questionText.split(' ')
    // let fillBlanksChosen = typeof question.correctAnswer === 'string'
    // 		? question.correctAnswer.split(',')
    // 		: question.correctAnswer
    let fillInTheBlankPhrases = []

    //Reset the consecutive phrases
    if (fillBlanksChosen && fillBlanksChosen.length > 0) {
      fillBlanksChosen = fillBlanksChosen.sort()
      let phraseCount = ''
      let prevIndex = ''
      let space = ''
      fillBlanksChosen.forEach(m => {
        if (m || m === 0) {
          if (((prevIndex || prevIndex === 0) && m === prevIndex + 1 * 1) || (!prevIndex && prevIndex !== 0)) {
            phraseCount = phraseCount || 0
            fillInTheBlankPhrases[phraseCount] = fillInTheBlankPhrases[phraseCount]
              ? fillInTheBlankPhrases[phraseCount] += space + arrayWords[m]
              : arrayWords[m]
            space = ' '
          } else {
            fillInTheBlankPhrases[++phraseCount] = arrayWords[m]
          }
        }
        prevIndex = m
      })
    }
    return fillInTheBlankPhrases
  }

  const handleRemoveQuestionRecordingOpen = (assessmentQuestionId, recordingFileUploadId) => {
    setIsShowingModal_removeQuestionRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setRecordingFileUploadId(recordingFileUploadId)
  }
  const handleRemoveQuestionRecordingClose = () => setIsShowingModal_removeQuestionRecording(false)
  const handleRemoveQuestionRecording = () => {
    let newQuestion = {...question}
    removeAssessmentQuestionQuestionRecording(personId, assessmentQuestionId, recordingFileUploadId)
    handleRemoveQuestionRecordingClose()
    newQuestion.questionRecordingFileUrl = ''
    setQuestion(newQuestion)
    setDeleted_recordingFileUploadId(recordingFileUploadId)
    setSelectedRecording_question('')
  }

  const handleRemoveAnswerRecordingOpen = (assessmentQuestionId, fileUploadId, answerIndex, multipleAnswerType) => {
    setIsShowingModal_removeAnswerRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
    setAnswerIndex(answerIndex)
    setMultipleAnswerType(multipleAnswerType)
  }
  const handleRemoveAnswerRecordingClose = () => {
    setIsShowingModal_removeAnswerRecording(false)
    setAnswerIndex('')
    setMultipleAnswerType('')
  }
  const handleRemoveAnswerRecording = () => {
    let newQuestion = {...question}
    let multipleChoiceAnswers = [...multipleChoiceAnswers]
    let multipleAnswerAnswers = [...multipleAnswerAnswers]
    removeAssessmentQuestionAnswerRecording(personId, assessmentQuestionId, fileUploadId)
    handleRemoveAnswerRecordingClose()
    if (multipleAnswerType === 'multipleChoice') {
      multipleChoiceAnswers[answerIndex].recording = ''
    } else if (multipleAnswerType === 'multipleAnswer') {
      multipleAnswerAnswers[answerIndex].recording = ''
    }
    newQuestion.answers[answerIndex].recordingFileUrl = ''
    setDeleted_fileUploadId(fileUploadId)
    setQuestion(newQuestion)
    setMultipleChoiceAnswers(multipleChoiceAnswers)
    setMultipleAnswerAnswers(multipleAnswerAnswers)
  }

  const handleRemoveSolutionRecordingOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeSolutionRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }
  const handleRemoveSolutionRecordingClose = () => setIsShowingModal_removeSolutionRecording(false)
  const handleRemoveSolutionRecording = () => {
    let newQuestion = {...question}
    removeAssessmentQuestionSolutionRecording(personId, assessmentQuestionId, fileUploadId)
    handleRemoveSolutionRecordingClose()
    newQuestion.solutionRecordingFileUrl = ''
    setDeleted_fileUploadId(fileUploadId)
    setSelectedRecording_solution('')
    setQuestion(newQuestion)
  }

  const chooseStandards = (standardIds) => {
    let newQuestion = {...question}
    newQuestion['standardIds'] = standardIds && standardIds.length > 0 && standardIds.reduce((acc, m) => acc && acc.length ? acc.concat(m.id) : [m.id], [])
    setQuestion(newQuestion)
    setStandardIds()
  }

  return !question ? null : (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={styles.center}>
            <div className={classes(styles.heading, styles.whiteText)}>
              {question?.assessmentQuestionId && question.assessmentQuestionId !== guidEmpty
                ? 'Edit Question'
                : 'Add Question'
              }
            </div>
            <div className={styles.moreTop}>
              <TextDisplay label={'Assessment'} text={assessment && assessment.name} className={styles.whiteText}/>
            </div>
          </div>
            <div>
              {question.assessmentQuestionId
              ? <TextDisplay label={'Question Type'} text={questionTypes && questionTypes.length > 0 && questionTypes.filter(m => m.id === question.questionTypeCode)[0].label} className={styles.whiteText} />
                : <div>
                  <SelectSingleDropDown
                    id={`questionTypeCode`}
                    name={`questionTypeCode`}
                    label={'Question Type'}
                    labelClass={styles.whiteText}
                    value={question.questionTypeCode || ''}
                    options={questionTypes}
                    className={styles.moreBottomMargin}
                    height={`medium`}
                    onChange={changeItem}
                    disabled={question.assessmentQuestionId}
                    required={true}
                    whenFilled={question.questionTypeCode}
                    error={errors.questionTypeCode} />
                </div>
              }
              {question.assessmentQuestionId &&
                <div className={classes(styles.instructions, styles.muchLeft)}>If you want to change the question type, delete and start again.</div>
              }
            </div>
            {question.questionTypeCode && question.questionTypeCode !== '0' && question.questionTypeCode !== 'PASSAGE' &&
              <div>
                <InputText
                  id={`pointsPossible`}
                  name={`pointsPossible`}
                  size={'super-short'}
                  label={'Points'}
                  labelClassName={styles.whiteText}
                  numberOnly={true}
                  value={question.pointsPossible || ''}
                  onChange={changeItem}
                  required={true}
                  whenFilled={question.pointsPossible}
                  autoComplete={'dontdoit'}
                  error={errors.pointsPossible} />
              </div>
            }
            {gradingType === 'STANDARDSRATING' && question.questionTypeCode !== 'PASSAGE' &&
              <div>
                <div className={styles.listPosition}>
                  <InputDataList
                    label={'Standards'}
                    labelClass={styles.whiteText}
                    name={'standardIds'}
                    options={standards || [{ id: '', value: '' }]}
                    value={standardIds}
                    multiple={true}
                    height={`medium`}
                    className={styles.moreTop}
                    onChange={chooseStandards} />
                </div>
              </div>
            }
            {question.questionTypeCode && question.questionTypeCode !== '0' && question.questionTypeCode !== 'MATCHING' &&
              <div>
                <InputTextArea
                  label={question.questionTypeCode === 'PASSAGE' ? 'Enter the passage' : 'Enter the question'}
                  name={'questionText'}
                  value={question.questionText || ''}
                  autoComplete={'dontdoit'}
                  labelClass={styles.whiteText}
                  onChange={changeItem}
                  required={true}
                  whenFilled={(question.questionTypeCode === 'FILLBLANK' && question.questionText)
                    || (question.questionTypeCode !== 'FILLBLANK' && (question.questionText || selectedFile_question || selectedRecording_question))}
                  error={errors.questionText} />
                <div className={classes(styles.row, styles.includePicture)} ref={questionFile}>
                  {/*<div className={globalStyles.instructionsBigger}>Enter a question above and / or</div>*/}
                  <div className={styles.row} onClick={() => handleFileUploadOpen(true)}>
                    <Icon pathName={'camera2'} premium={true} className={styles.icon} />
                    <div className={classes(styles.link, styles.littleTop)}>Picture</div>
                  </div>
                  <div className={styles.row} onClick={() => handleVoiceRecordingOpen(true)}>
                    <Icon pathName={'microphone'} premium={true} className={styles.iconPosition} />
                    <div className={classes(styles.link, styles.littleTop)}>Voice recording</div>
                  </div>
                </div>
                <img src={''} alt={'New'} ref={imageViewer} />
                {!selectedRecording_question && question.questionRecordingFileUrl &&
                  <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'} controls="controls" className={styles.audioLeftQuestion}
                    isSmall={true} isOwner={question.isOwner}
                    deleteFunction={(event) => handleRemoveQuestionRecordingOpen(question.assessmentQuestionId, question.questionRecordingFileUploadId)} />
                }
                {selectedRecording_question &&
                  <AudioDisplay src={selectedRecording_question && window.URL.createObjectURL(selectedRecording_question)} preload={'auto'} controls="controls" className={styles.audioLeftQuestion}
                    isSmall={true} isOwner={question.isOwner}
                    deleteFunction={(event) => handleRemoveQuestionRecordingOpen(question.assessmentQuestionId, question.questionRecordingFileUploadId)} />
                }
                {question.questionFileUrl &&
                  <ImageDisplay key={'questionFile'} linkText={''} url={question.questionFileUrl} isOwner={question.isOwner}
                    deleteFunction={() => handleRemoveFileOpen(question.assessmentQuestionId, question.questionFileUploadId)} />
                }
                <hr />
              </div>
            }
            {question.questionTypeCode === 'TRUEFALSE' &&
              <div>
                <RadioGroup
                  data={[{ label: 'True', id: 'true' }, { label: 'False', id: 'false' },]}
                  name={`answerTrueFalse`}
                  horizontal={true}
                  className={styles.radio}
                  initialValue={question.correctAnswer}
                  onClick={handleTrueFalse} />
              </div>
            }
            {question.questionTypeCode === 'SINGLEENTRY' &&
              <div>
                <Checkbox
                  id={'answerNumberOnly'}
                  name={'answerNumberOnly'}
                  label={'Number only'}
                  labelClass={classes(styles.checkboxLabel, styles.whiteText)}
                  checked={question.answerNumberOnly || false}
                  onClick={toggleAnswerNumberOnly}
                  className={styles.button} />
                <InputText
                  id={`correctAnswer`}
                  name={`correctAnswer`}
                  size={question.answerNumberOnly ? 'super-short' : 'medium-long'}
                  label={'Answer'}
                  labelClassName={styles.whiteText}
                  value={question.correctAnswer || ''}
                  onChange={changeItem}
                  numberOnly={question.answerNumberOnly}
                  autoComplete={'dontdoit'}
                  required={true}
                  whenFilled={question.correctAnswer} />
              </div>
            }
            {question.questionTypeCode === 'SINGLEENTRY' &&
              <div>
                <AssessmentAnswerVariation 
                  addAnswerVariation={handleAddAnswerVariation}
                  answerVariations={question.answerVariations} 
                  isAuthor={isAuthor}
                  removeAnswerVariation={handleRemoveAnswerVariation} />
              </div>
            }
            {question.questionText && question.questionTypeCode === 'FILLBLANK' &&
              <div>
                <TextDisplay label={'Full phrase'} text={question.questionText} className={styles.whiteText} />
              </div>
            }
            {question.questionText && question.questionTypeCode === 'FILLBLANK' &&
              <div>
                <TextDisplay label={
                  <div className={styles.row}>
                    <div>Blank-out phrase</div>
                    <div className={classes(globalStyles.instructionsBigger, styles.liftUp)}>Click on a word to blank it out</div>
                  </div>
                } text={fillInTheBlankDisplay()} className={styles.whiteText}/>
              </div>
            }
            {fillInTheBlankPhrases && fillInTheBlankPhrases.length > 0 && question.questionTypeCode === 'FILLBLANK' &&
              <div>
                {fillInTheBlankPhrases.map((m, i) => !m
                  ? null
                  : <TextDisplay label={fillInTheBlankPhrases.length === 1 ? 'Answer' : `Answer #${i + 1 * 1}`} text={m} key={i} className={styles.whiteText} />
                )}
                <div className={styles.positionVariations}>
                  <AssessmentAnswerVariation 
                    addAnswerVariation={handleAddAnswerVariation}
                    answerVariations={question.answerVariations} 
                    isAuthor={isAuthor}
                    removeAnswerVariation={handleRemoveAnswerVariation} />
                </div>
              </div>
            }
            {question.questionTypeCode === 'MATCHING' &&
              <div>
                <AssessmentMatching 
                  handleFileUploadOpen={handleFileUploadOpen}
                  handleMatchingCorrectAnswers={handleMatchingCorrectAnswers}
                  handleMatchingQuestionText={handleMatchingQuestionText}
                  handleMatchingToMatchText={handleMatchingToMatchText}
                  handleSubmit={handleSubmit}
                  handleVoiceRecordingOpen={handleVoiceRecordingOpen}
                  isAuthor={isAuthor}
                  multipleAnswerAnswers={multipleAnswerAnswers}
                  multipleMatchingAnswers={multipleMatchingAnswers}
                  question={question} 
                  removeAnswerFileOpen={removeAnswerFileOpen}
                  removeAnswerRecordingOpen={handleRemoveAnswerRecordingOpen}
                  removeMatchingRemoveLine={removeMatchingRemoveLine}
                  removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                  removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                  removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen}
                  removeToMatchFileOpen={removeToMatchFileOpen}
                  viewMode={'AddOrUpdate'} />
              </div>
            }
            {question.questionTypeCode === 'ESSAY' &&
              <div>
                <AssessmentEssayKeyword 
                  addKeywordPhrase={handleAddKeywordPhrase}
                  isAuthor={isAuthor}
                  keywordCountAccuracy={question.keywordCountAccuracy}
                  keywordPhrases={question.keywordPhrases} 
                  removeKeywordPhrase={handleRemoveKeywordPhrase} 
                  updateKeywordCountAccuracy={changeItem} />
              </div>
            }
            {question.questionTypeCode === 'PASSAGE' &&
              <div>
                <span className={styles.labelNotice}>This is intended to be a reading or example of a problem which will be followed by two or more questions.</span><br />
              </div>
            }
            {question.questionTypeCode === 'MULTIPLECHOICE' &&
              <div>
                {alpha.map((alph, index) => {  //eslint-disable-line
                  if (multipleCount >= index + 1) {
                    return <div key={index}>
                      <div className={styles.row}>
                        <input type={`radio`} name={'multipleChoice'} id={'multipleChoice'} value={index}
                          checked={(multipleChoiceAnswers[index] && multipleChoiceAnswers[index].isCorrect) || false}
                          onClick={handleMultipleChoiceCorrectAnswer} onChange={() => { }} />
                        <span className={styles.label}>{alph}</span>
                        <InputTextArea
                          label={''}
                          id={alpha[index]}
                          name={alpha[index]}
                          value={(multipleChoiceAnswers[index] && multipleChoiceAnswers[index].answerText) || ''}
                          labelClass={styles.whiteText}
                          autoComplete={'dontdoit'}
                          onChange={(event) => handleMultipleChoiceAnswer(event, index)} />
                        {multipleCount > 3 &&
                          <Icon pathName={`trash2`} premium={true} className={styles.icon} onClick={() => handleRemoveMultipleChoiceAnswer(index)} />
                        }
                      </div>
                      <div className={classes(styles.row, styles.muchMoreLeft)} id={`answerFile${index}`}>
                        {/*<div className={globalStyles.instructionsBigger}>Enter an answer above and / or</div>
                                                  Removed text due to simplicity of page and to clean up modal. It's self explanitory*/}
                        <div className={styles.row} onClick={() => handleFileUploadOpen(false, index)}>
                          <Icon pathName={'camera2'} premium={true} className={styles.icon} />
                          <div className={classes(styles.link, styles.littleTop)}>Picture</div>
                        </div>
                        <div className={styles.row} onClick={() => handleVoiceRecordingOpen(false, index)}>
                          <Icon pathName={'microphone'} premium={true} className={styles.iconPosition} />
                          <div className={classes(styles.link, styles.littleTop)}>Voice recording</div>
                        </div>
                      </div>
                      <img src={''} alt={'New'} id={`imageViewer${question.questionTypeCode}${index}`} />
                      {(!(multipleChoiceAnswers[index] && multipleChoiceAnswers[index].recording)
                        && (question && question.answers && question.answers.length > 0 && question.answers[index] && question.answers[index].recordingFileUrl)) &&
                        <AudioDisplay src={question.answers[index].recordingFileUrl} preload={'auto'} controls="controls" className={styles.audioLeftQuestion}
                          isSmall={true} isOwner={question.isOwner}
                          deleteFunction={(event) => handleRemoveAnswerRecordingOpen(question.assessmentQuestionId, question.answers[index].recordingFileUploadId, index, 'multipleChoice')} />
                      }
                      {multipleChoiceAnswers[index] && multipleChoiceAnswers[index].recording
                        && deleted_fileUploadId !== multipleChoiceAnswers[index].recordingFileUploadId &&
                        <AudioDisplay src={window.URL.createObjectURL(multipleChoiceAnswers[index].recording)} preload={'auto'} controls="controls" className={styles.audioLeftQuestion}
                          isSmall={true} isOwner={question.isOwner}
                          deleteFunction={(event) => handleRemoveAnswerRecordingOpen(question.assessmentQuestionId, multipleChoiceAnswers[index].fileUploadId, index, 'multipleChoice')} />
                      }
                      {multipleChoiceAnswers[alpha.indexOf(alph)] && multipleChoiceAnswers[alpha.indexOf(alph)].fileUrl
                        && deleted_fileUploadId !== multipleChoiceAnswers[alpha.indexOf(alph)].fileUploadId &&
                        <ImageDisplay linkText={''} url={multipleChoiceAnswers[alpha.indexOf(alph)].fileUrl} isOwner={question.isOwner}
                          deleteFunction={() => removeAnswerFileOpen(question.assessmentQuestionId, multipleChoiceAnswers[alpha.indexOf(alph)].fileUploadId)} />
                      }
                      <hr />
                    </div>
                  }
                })}
                <a onClick={incrementMultipleCount} className={styles.link}>Add another multiple choice answer</a>
              </div>
            }
            {question.questionTypeCode === 'MULTIPLEANSWER' &&
              <div>
                {alpha.map((alph, index) => {  //eslint-disable-line
                  if (multipleCount >= index + 1) {
                    return <div key={index}>
                      <div className={styles.row}>
                        <input type={`checkbox`} id={alph} name={alph} value={index}
                          checked={(multipleAnswerAnswers?.length > 0 && multipleAnswerAnswers[index] && multipleAnswerAnswers[index].isCorrect) || false}
                          onClick={handleMultipleAnswerCorrectAnswer} onChange={() => { }} />
                        <span className={styles.label}>{alph}</span>
                        <InputTextArea
                          label={''}
                          id={alpha[index]}
                          name={alpha[index]}
                          autoComplete={'dontdoit'}
                          labelClass={styles.whiteText}
                          value={(multipleAnswerAnswers?.length > 0 && multipleAnswerAnswers[alpha.indexOf(alph)] && multipleAnswerAnswers[alpha.indexOf(alph)].answerText) || ''}
                          onChange={handleMultipleAnswerAnswer}
                          error={errors.correctAnswer} />
                        {multipleCount > 3 &&
                          <Icon pathName={`trash2`} premium={true} className={styles.icon} onClick={() => handleRemoveMultipleChoiceAnswer(index)} />
                        }
                      </div>
                      <div className={classes(styles.row, styles.includePicture, styles.muchMoreLeft)} id={`answerFile${index}`}>
                        {/*<div className={globalStyles.instructionsBigger}>Enter a question above and / or</div>
                                                  Removed text due to simplicity of page and to clean up modal. It's self explanitory*/}
                        <div className={styles.row} onClick={() => handleFileUploadOpen(false, index)}>
                          <Icon pathName={'camera2'} premium={true} className={styles.icon} />
                          <div className={classes(styles.link, styles.littleTop)}>Picture</div>
                        </div>
                        <div className={styles.row} onClick={() => handleVoiceRecordingOpen(false, index)}>
                          <Icon pathName={'microphone'} premium={true} className={styles.iconPosition} />
                          <div className={classes(styles.link, styles.littleTop)}>Voice recording</div>
                        </div>
                      </div>
                      <img src={''} alt={'New'} id={`imageViewer${question.questionTypeCode}${index}`} />
                      {(!(multipleAnswerAnswers[index] && multipleAnswerAnswers[index].recording)
                        && (question && question.answers && question.answers.length > 0 && question.answers[index] && question.answers[index].recordingFileUrl)) &&
                        <AudioDisplay src={question.answers[index].recordingFileUrl} preload={'auto'} controls="controls" className={styles.audioLeftQuestion} isSmall={true} isOwner={question.isOwner}
                          deleteFunction={(event) => handleRemoveAnswerRecordingOpen(question.assessmentQuestionId, question.answers[index].recordingFileUploadId, index, 'multipleAnswer')} />
                      }
                      {multipleAnswerAnswers[index] && multipleAnswerAnswers[index].recording
                        && deleted_fileUploadId !== multipleAnswerAnswers[index].recordingFileUploadId &&
                        <AudioDisplay src={window.URL.createObjectURL(multipleAnswerAnswers[index].recording)} preload={'auto'} controls="controls" className={styles.audioLeftQuestion} isSmall={true} isOwner={question.isOwner}
                          deleteFunction={(event) => handleRemoveAnswerRecordingOpen(question.assessmentQuestionId, multipleAnswerAnswers[index].fileUploadId, index, 'multipleAnswer')} />
                      }
                      {multipleAnswerAnswers[alpha.indexOf(alph)] && multipleAnswerAnswers[alpha.indexOf(alph)].fileUrl
                        && deleted_fileUploadId !== multipleChoiceAnswers[alpha.indexOf(alph)].fileUploadId &&
                        <ImageDisplay linkText={''} url={multipleChoiceAnswers[alpha.indexOf(alph)].fileUrl} isOwner={question.isOwner}
                          deleteFunction={() => removeAnswerFileOpen(question.assessmentQuestionId, multipleChoiceAnswers[alpha.indexOf(alph)].fileUploadId)} />
                      }
                      <hr />
                    </div>
                  }
                })}
                <a onClick={incrementMultipleCount} className={styles.link}>Add another multiple choice answer</a>
              </div>
            }
            {question.questionTypeCode && question.questionTypeCode !== 'PASSAGE' &&
              <div>
                <hr />
                <div className={styles.headerLabel}>Solution (optional)</div>
                <div className={styles.moreTop}>
                  <InputTextArea
                    label={'After the quiz is corrected, this explanation or picture will be displayed'}
                    id={'solutionText'}
                    name={'solutionText'}
                    value={question.solutionText || ''}
                    labelClass={styles.whiteText}
                    onChange={changeItem}
                    autoComplete={'dontdoit'} />
                </div>
                <div className={classes(styles.row, styles.includePicture)} ref={solutionFile}>
                  {/*<div className={globalStyles.instructionsBigger}>Enter a question above and / or</div>*/}
                  <div className={styles.row} onClick={() => handleFileUploadOpen(false, null, true)}>
                    <Icon pathName={'camera2'} premium={true} className={styles.icon} />
                    <div className={classes(styles.link, styles.littleTop)}>Picture</div>
                  </div>
                  <div className={styles.row} onClick={() => handleVoiceRecordingOpen(false, null, true)}>
                    <Icon pathName={'microphone'} premium={true} className={styles.iconPosition} />
                    <div className={classes(styles.link, styles.littleTop)}>Voice recording</div>
                  </div>
                </div>
                <img src={''} alt={'New'} ref={imageViewerSolution} />
                {!selectedRecording_solution && question.solutionRecordingFileUrl &&
                  <AudioDisplay src={question.solutionRecordingFileUrl} preload={'auto'} controls="controls" className={styles.audioLeftQuestion} isSmall={true} isOwner={question.isOwner}
                    deleteFunction={(event) => handleRemoveSolutionRecordingOpen(question.assessmentQuestionId, question.solutionRecordingFileUploadId)} />
                }
                {selectedRecording_solution &&
                  <AudioDisplay src={window.URL.createObjectURL(selectedRecording_solution)} preload={'auto'} controls="controls" className={styles.audioLeftQuestion} isSmall={true} isOwner={question.isOwner}
                    deleteFunction={(event) => handleRemoveSolutionRecordingOpen(question.assessmentQuestionId, question.solutionRecordingFileUploadId)} />
                }
                {question.solutionFileUploadId && question.solutionFileUrl
                  && deleted_fileUploadId !== question.solutionFileUploadId &&
                  <ImageDisplay linkText={''} url={question.solutionFileUrl} isOwner={question.isOwner}
                    deleteFunction={() => handleRemoveSolutionFileOpen(question.assessmentQuestionId, question.solution.fileUploadId)} />
                }
                <hr />
              </div>
            }
            <div>
              <span className={styles.error}>{errors.correctAnswer}</span>
            </div>
            <div>
              <div className={classes(styles.rowRight)}>
                <div className={styles.cancelLink} onClick={handleClose}>
                  Close
                </div>
                <div>
                  <ButtonWithIcon label={'Submit'} icon={'checkmark_circle'} onClick={processForm} />
                </div>
              </div>
            </div>
        </div>
      </div>
      {isShowingModal_keywordCount &&
        <MessageModal handleClose={handleKeywordCountClose} heading={'Missing keyword count accuracy?'}
          explainJSX={"You have entered a keyword for this essay, but you didn't indicate how many keywords that need to be present in the essay to get full credit. Do you want to save this entry anyway?"} isConfirmType={true}
          onClick={handleKeywordCountSave} />
      }
      <FileUploadModalWithCrop 
        isOpen={isShowingFileUpload}
        handleClose={handleFileUploadClose} 
        title={'Choose Assessment Picture'}
        personId={personId} submitFileUpload={handleFileUploadSubmit} answerIndex={answerIndex}
        file={isQuestionFile
          ? selectedFile_question
          : isSolutionFile
            ? selectedFile_solution
            : isMatchingFile
              ? question.matches && question.matches.length > 0 && question.matches[answerIndex] && question.matches[answerIndex].file
              : question.answers && question.answers.length > 0 && question.answers[answerIndex] && question.answers[answerIndex].file
          }
          handleInputFile={isQuestionFile
            ? handleInputFile_question
            : isSolutionFile
              ? handleInputFile_solution
              : isMatchingFile
                ? handleInputFile_toMatch
                : handleInputFile_answer}
          acceptedFiles={".jpg, .jpeg, .tiff, .gif, .png, .bmp, .docx, .doc, .pdf"}
          handleCancelFile={isQuestionFile
            ? handleRemoveInputFile_question
            : isSolutionFile
              ? handleRemoveInputFile_solution
              : isMatchingFile
                ? handleRemoveInputFile_toMatch
                : handleRemoveInputFile_answer
          } />

      <VoiceRecordingModal2 
        isOpen={isShowingVoiceRecording}
        handleClose={handleVoiceRecordingClose} 
        title={'Assessment Question'} 
        label={'File for'}
        personId={personId}
        record={record}
        submitFileUpload={handleVoiceRecordingSubmit}
        recordedBlob={isQuestionFile
          ? selectedRecording_question
          : isSolutionFile
            ? selectedRecording_solution
            : isMatchingFile
              ? question.matches && question.matches.length > 0 && question.matches[answerIndex] && question.matches[answerIndex].recording
              : question.answers && question.answers.length > 0 && question.answers[answerIndex] && question.answers[answerIndex].recording
        }
        handleCancelFile={isQuestionFile
          ? handleRemoveInputRecording_question
          : isSolutionFile
            ? handleRemoveInputRecording_solution
            : isMatchingFile
              ? handleRemoveInputRecording_toMatch
              : handleRemoveInputRecording_answer}
        startRecording={startRecording}
        stopRecording={stopRecording}
        blobUrl={blobUrl}
        onTheAir={onTheAir} />

      {isShowingModal_missingInfo &&
        <MessageModal handleClose={handleMissingInfoClose} heading={'Missing information'}
          explainJSX={messageInfoIncomplete} onClick={handleMissingInfoClose} />
      }
      {isShowingModal_removeQuestionRecording &&
        <MessageModal handleClose={handleRemoveQuestionRecordingClose} heading={'Remove this question recording?'}
          explainJSX={'Are you sure you want to delete this question recording?'} isConfirmType={true}
          onClick={handleRemoveQuestionRecording} />
      }
      {isShowingModal_removeAnswerRecording &&
        <MessageModal handleClose={handleRemoveAnswerRecordingClose} heading={'Remove this answer recording?'}
          explainJSX={'Are you sure you want to delete this answer recording?'} isConfirmType={true}
          onClick={handleRemoveAnswerRecording} />
      }
      {isShowingModal_removeSolutionRecording &&
        <MessageModal handleClose={handleRemoveSolutionRecordingClose} heading={'Remove this solution recording?'}
          explainJSX={'Are you sure you want to delete this solution recording?'} isConfirmType={true}
          onClick={handleRemoveSolutionRecording} />
      }
    </div>
  )
}

export default AssessmentItemModal