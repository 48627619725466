import React, { useRef, useEffect } from 'react'
import styles from './VoiceRecordingModal.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
//import {ModalContainer, ModalDialog} from '../react-modal-dialog/lib/index.js'
import TextDisplay from '../TextDisplay'
import ButtonWithIcon from '../ButtonWithIcon'
import classes from 'classnames'
import { useMediaQuery } from "react-responsive"
var ReactMic

function VoiceRecordingModal(props) {
  const { 
    handleClose, 
    isOpen,
    label, 
    onStop, 
    record, 
    recordedBlob, 
    startRecording, 
    stopRecording, 
    submitFileUpload, 
    title, 
  } = props
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  useEffect(() => {
    ReactMic = require('react-mic').ReactMic
  }, [])

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={classes(globalStyles.pageTitle, styles.whiteText)}>
            Voice Recording
          </div>
          <div className={styles.containerName}>
            <TextDisplay label={label} text={title} />
          </div>
          <div>
            <ReactMic
              record={record}
              className={styles.recorder}
              onStop={onStop}
              strokeColor="white"
              backgroundColor="#147EA7" />
          </div>
          <audio src={recordedBlob && recordedBlob.blobURL} preload={'auto'} controls="controls">
            This browser does not support this audio control
          </audio>
          <div className={styles.rowButtons}>
            <ButtonWithIcon label={'Start'} icon={'microphone'} onClick={startRecording} className={styles.submitButton} />
            <ButtonWithIcon label={'Stop'} icon={'stop_circle'} onClick={stopRecording} className={styles.submitButton} changeRed={true} />
            <div className={styles.moreLeft}>
              <ButtonWithIcon label={'Save'} icon={'checkmark_circle'} onClick={submitFileUpload} disabled={!(recordedBlob && recordedBlob.blobURL)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoiceRecordingModal